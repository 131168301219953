import { useContext, useEffect, useRef } from "react";
import { APIContext } from "../../../../layers";
import SmallCard from "../../userSmallCard";
import './styling/Follower.css'
import { getShortDate } from "../../../Misc-Components";
import { useElementsOnScreen } from "../../../Misc-Components/useElementsOnScreen";

const Followers = ({uid, followers, visitorInfo, dispatcher}) => {
    const API = useContext(APIContext);
    const followersFetchRef = useRef({});
    const [containerRef, isVisible] = useElementsOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: "0.5"
    }, false);

    useEffect(() => {
        const {more, loading} = followers;
        if(!isVisible || !more || loading)
            return
        
        followersFetchRef.current.controller = new AbortController();
        const signal = followersFetchRef.current.controller.signal;
        dispatcher({type: "FOLLOWERS_FETCH", value: {loading: true}});
        API.getFollowers.uid(uid, followers.cursor, {signal})
            .then(json => {
                dispatcher({type: "FOLLOWERS", value: json.followers});
            })
            .catch(err => {
                if(err instanceof DOMException && err.name === "AbortError")
                    return;
            })
    }, [isVisible, uid, followers, API, dispatcher]);

    useEffect(() => {
        const controller = followersFetchRef.current?.controller;

        return () => {
            if(followers.loading) {
                if(controller) controller.abort();
                dispatcher({type: "FOLLOWERS_ABORT", value: {loading: false}});
            }
        }
    // eslint-disable-next-line
    }, []);

    if(!uid)
        return <></>;

    if(followers.count === 0)
        return <div className="URS-EmptySectionMessage">No Followers</div>

    return (
        <>
        <div className='SmallUserCard-Wrapper'>
            {/* Followers */}
            {followers.users.map((v, i) => {
                const {_id, username, displayname} = v.follower;
                return (
                    <div className='SmallCard-Container' key={username}>
                        <SmallCard 
                            userId={_id} 
                            avatarURL={API.getUserAvatar(username)} 
                            username={username} 
                            displayname={displayname}
                            
                            rightSide={<span className="FollowDate">{getShortDate(v.followedAt)}</span>}
                            />
                    </div>
                )
            })}
        </div>
        {followers.more && 
            <div ref={containerRef}>&nbsp;</div>
        }
        {
            followers.loading
            && 
            <div style={{justifySelf: "center"}}>
                <div className="spinner" style={{height: "2rem", width: "2rem", color: "var(--URBAN-GRAY)", justifyContent: "center"}}/>
            </div>
        }
        </>
    );
}

export default Followers;