import '../styling/Create_v2.css'
import {ReactComponent as BookIcon} from '../../icons/svg/book.svg'
import {ReactComponent as SaveIcon} from '../../icons/svg/save.svg'
import {ReactComponent as EyeIcon } from '../../icons/svg/eye.svg'
import { 
    useState, 
    useCallback, 
    useRef, 
    useEffect,
    useContext
    } from 'react';
import { useParams } from 'react-router-dom';
import * as Cre from '../../components/Create-Versions/Create_v2-Components';
import { LimitInput } from '../../components/Misc-Components';
import { configureBody } from '../../components/Renders/Configures';
import Document from '../../components/Document-Components/v2/document';
import { APIContext, AuthContext, NotifContext } from '../../layers'

const KB = 1024;
const MB = 1024*KB;

function getMaxValue(jsonARR, prop){
    let maxV = null;

    for( const entry of jsonARR ){
        if(maxV === null || entry[prop] > maxV)
            maxV = entry[prop];
    }

    if(maxV === null)
        maxV = 0;
        
    return maxV;
}

const Create = () => {
    const API = useContext(APIContext);
    const Auth = useContext(AuthContext);
    const NOTIF = useContext(NotifContext);
    const { draftID } = useParams();
    const [ docBanner,   setDocBanner ] = useState(null); /* or an object {banner: "", smallBanner: ""} if both are present */
        const docBannerRefs = useRef(null);
    //      --> Banner's file is referenced using the key 'banner' in docFiles
    const [ docTitle,    setTitle     ] = useState("");
    const [ docSubtitle, setSubtitle  ] = useState("");
    // const [ docSummary, setSummary  ] = useState("");
    const [ docBody,     setBody      ] = useState([]); // ([blankNode]);
    const [ RenderV,     setRV        ] = useState('vN01');
    const [ baseKey,     setBkey      ] = useState(0);
    const [ loading,     setLoading   ] = useState(true);
    const [ previewActive, setPreviewActive ] = useState(false);
    const [ publishing, setPublishing ] = useState(false);
    const docContainerRef = useRef(null);
    const instr = useRef([]);
        // [DEL|ADD|MOD FILE? KEY INDEX?]
    const docFiles = useRef({
        saved: {}, 
        _info: {
            totalSize: 0,
            maxCap: 4 * MB
        }
    });
    //      --> // One File per node's key
    //      -> addFile  // Overwrite previous file
    //      -> removeFile
    //      -> getFile // 

    useEffect(() => {
        const copyDocFiles = docFiles.current;
        return () => {
            //console.groupCollapsed('revokeFiles upon Create exit');
            //console.log('docFiles:', copyDocFiles);
            for(const fKey in copyDocFiles){
                //console.log(fKey);
                if(fKey === '_info') continue;
                if(fKey === 'saved'){
                    for(const saved_fKey in copyDocFiles.saved){
                        URL.revokeObjectURL(copyDocFiles.saved[saved_fKey].url);
                    }
                }
                else
                    URL.revokeObjectURL(copyDocFiles.fKey.url)
            }
            //console.groupEnd();
        }
    },[]);

    // Get Draft content from server.
    useEffect(() => {
        async function fetchDraft(){
            // console.log('Fetched Draft.');
            const results = await API.getDraft(draftID);
            setLoading(false);
            if(results.errMsg){
                console.error('An Error Has Occurred.', results.errMsg);
                NOTIF.createNotif(['error'], null, `Failed to fetch draft.`);
                return;
            }
            
            const draft = results.draft;
            // console.log('draft:', draft);

            let totalSize = 0;
            for(const fKey in results.files){
                totalSize += results.files[fKey].size;
                
                docFiles.current.saved[fKey] = {
                    file: results.files[fKey], 
                    url: URL.createObjectURL(
                        new Blob([new Uint8Array(results.files[fKey].buffer.data)])
                        )
                }
            }

            docFiles.current._info.totalSize = totalSize;
            
            setTitle(draft.title);
                updBrowserTitle(draft.title);
            setSubtitle(draft.subtitle);
            setBkey(draft.body.length? getMaxValue(draft.body, 'key') : 0);
            setRV(draft.render);
            setBody(configureBody(draft.render, draft.body, docFiles.current));
            docBannerRefs.current = {
                src: docFiles.current.saved['banner']?.url,
                smallSrc: docFiles.current.saved['banner_sm']?.url
            }
            if(docContainerRef.current.offsetWidth <= 500)
                docBannerRefs.current.currentBanner = docBannerRefs.current.smallSrc;
            else
                docBannerRefs.current.currentBanner = docBannerRefs.current.src;
            setDocBanner({src: docBannerRefs.current.currentBanner});
        }
        if(loading) // ?
            fetchDraft();
    }, [draftID, loading, API, NOTIF]);

    // == Functions ==

    useEffect(() => {

        function toggleBanner() {
            if(!docBannerRefs.current)
                return;

            const bR = docBannerRefs.current;

            const updateToSmall = docContainerRef.current.offsetWidth <= 500;
            const updatedBanner = (updateToSmall)
                ?
                (bR.currentBanner !== bR.smallSrc
                    ? bR.smallSrc : null )
                : 
                (bR.currentBanner !== bR.src
                    ? bR.src : null )
                ;

            if(updatedBanner){
                bR.currentBanner = updatedBanner
                setDocBanner({src: bR.currentBanner});
            }
        }

        window.addEventListener('resize', toggleBanner);

        return () => {
            window.removeEventListener('resize', toggleBanner);
        }

    }, [docContainerRef, setDocBanner])

    const updBrowserTitle = title => {document.title = `${title? title : 'Untitled'} - UrbanCoffee.io`};

    const updTitle  = useCallback(input => setTitle(input.target.value.trimStart()), [setTitle]);
    const updSubTtl = useCallback(input => setSubtitle(input.target.value.trimStart()), [setSubtitle]);
    // const updSummary = useCallback(input => setSummary(input.target.value), [setSummary]);

    // Send the Draft Content to the server to be saved or published
    const saveDraft = async () => {
        const [finishNotif] = NOTIF.createNotif("load", null, "Saving Draft...");
        const formdata = new FormData();
        const saveResponseBundle = {good: true};
        
        const filekey = docFiles.current.hotFile;
        if(filekey !== 'undefined' && docFiles.current[filekey]){
            const file = docFiles.current[filekey].file;
            formdata.append(filekey, file, file.name)
            if(docFiles.current[filekey].crops)
                formdata.append('crop', JSON.stringify(docFiles.current[filekey].crops));
        }

        formdata.append('title', docTitle);
        formdata.append('subtitle', docSubtitle);
        formdata.append('renderV', RenderV);
        // formdata.append('summary', docSummary);
        instr.current.forEach(i => {
            formdata.append('instr[]', i)
        })

        docBody.forEach((entry) => {
            formdata.append('body[]', JSON.stringify(entry));
        });

        
        const results = await API.saveDraft(draftID, formdata)
        if(results.errMsg){
            finishNotif("failure", results.errMsg); //"An error occurred when saving. Try again in a few minutes.");
            console.error('An Error Has Occurred.', results.errMsg);
            //setErrorMsgs(results.errMsg);
            return {...saveResponseBundle, good: false};
        }

        if(results.newTotalSize) saveResponseBundle.newTotalSize = results.newTotalSize;

        finishNotif("success", results.msg);
        instr.current = []; // clear array
        setBody(docBody.reduce((prev, node) => {
            if(node.modif && (node.modif === 'DELETE' || node.modif === 'CLEAR')){
                return prev;
            }
            delete node.modif;
            return [...prev, node];
        }, []));

        return {...saveResponseBundle};
    }

    const publishDraft = async () => {
        const save = await saveDraft();
        if(!save.good) return;

        const [finishedNotif] = NOTIF.createNotif(["load"], null, "Publishing Draft...");
        const publishSuccessful = await API.publishDraft(draftID);
        if(!publishSuccessful.ok){
            finishedNotif("failure", publishSuccessful.errMsg);
                
            for(let key in publishSuccessful.errList){
                NOTIF.createNotif(["error"], null, publishSuccessful.errList[key]);
            }
        }
        else{
            finishedNotif("success", "Draft successfully published.");
            window.location.href = `/article/${publishSuccessful.docID}`;
        }

        return publishSuccessful.ok;
    }

    // docFile API
    const addFile = useCallback((key, file) => {
        if(!file) return "";

        if(docFiles.current[key])
            URL.revokeObjectURL(docFiles.current[key].url);

        const url = URL.createObjectURL(file);
        docFiles.current[key] = {url, file};
        
        return url;
    }, [docFiles])

    // >>
        const addBannerFile = (key, file, crops) => {
            const url = addFile(key, file); // add Banner file
            docFiles.current[key].crops = crops; // add crops for 2.5 and 2 aspect ratios. 2 could be null
            return url;
        }
    // >>

    const getFile = useCallback((key, getSaved) => {
        let obj = getSaved? 
                    docFiles.current.saved?.[key]?.url 
                  : docFiles.current[key]?.url;
        return obj;
    }, [docFiles]);

    const getInfo = {
        totalFileInfo: () => {return {...docFiles.current?._info}},
        oneFileInfo: (key, getSaved) => {
            let obj = getSaved? docFiles.current.saved?.[key] : docFiles.current[key];
            return obj;
        }
    };

    const removeFile = useCallback((key) => {
        if(docFiles.current[key]){
            URL.revokeObjectURL(docFiles.current[key]);
            delete docFiles.current[key];
        }
        if(docFiles.current.saved[key]){
            URL.revokeObjectURL(docFiles.current.saved[key]);
            instr.current.push(`DEL FILE ${key}`);
            docFiles.current._info.totalSize -= docFiles.current.saved[key].file.size;
            delete docFiles.current.saved[key];
        }
    }, [docFiles, instr]);

    const confirmFile_Template = async (key, CB) => {
        if(docFiles.current[key])
            docFiles.current.hotFile = key;
        const save = await saveDraft(); // save: {good: BOOL, ...(misc)};
        if(save.good){
            CB(key, save);
        }
        delete docFiles.current.hotFile;
        return save.good;
    }

    const confirmFile = async (key) => confirmFile_Template(key, (key, save) => {
        // move hotfile to saved category
        docFiles.current.saved[key] = docFiles.current[key];
        docFiles.current._info.totalSize = save.newTotalSize;
        delete docFiles.current[key];
    });

    const confirmBannerFile = async (mainBanner, subBanner) => confirmFile_Template('banner', (key, save) => {
        // docFiles.current.saved[key] = mainBanner; //?

        URL.revokeObjectURL(docFiles.current.saved['banner']);
        URL.revokeObjectURL(docFiles.current.saved['smbanner']);
        
        docFiles.current.saved['banner'] = {
            file: {size: save.cropSize},
            url: mainBanner
        }
        docFiles.current.saved['smbanner'] = {
            file: {size: 0},
            url: subBanner
        }
        
        docFiles.current._info.totalSize = save.newTotalSize;
        delete docFiles.current[key];
    })

    const fileAPI = {addFile, getFile, getInfo, removeFile, confirmFile};

    const togglePreview = () => setPreviewActive(prev => !prev);

    const updateBanner = (upd) => {
        docBannerRefs.current = upd;
        const bR = docBannerRefs.current;

         // if a subcrop is not picked, then smallSrc is null. thus replaced with src
        if(!bR.smallSrc)
            bR.smallSrc = bR.src;

        if(docContainerRef.current.offsetWidth <= 500)
            bR.currentBanner = bR.smallSrc;
        else
            bR.currentBanner = bR.src;
        setDocBanner({src: bR.currentBanner});
    }

    return (
        <>
        <div id='Create'>
            <header>
                <div className='Create-Hdr-Title'>
                    <h1>Create - {docTitle.length ? docTitle : 'Untitled'}</h1>
                </div>
                <div className='Create-Hdr-Options'>
                    <button onClick={() => saveDraft()} className='Create-Hdr-Btn Create-Hdr-Save-Btn'>
                        Save Draft</button>
                    <button onClick={() => setPublishing(true)} className='Create-Hdr-Btn Create-Hdr-Pub-Btn'>
                        Publish</button>
                </div>
            </header>

            <main>
                {/* For Documents, these three are consistent. */}
                <Cre.Banner cb={{...fileAPI, addFile: addBannerFile, confirmFile: confirmBannerFile, updateSelf: updateBanner}} src={getFile('banner', true)}/>

                <LimitInput placeholder={"Title"}    lim={50}  onChange={updTitle}  value={docTitle} onBlur={updBrowserTitle}/>
                <LimitInput placeholder={"Subtitle"} lim={100} onChange={updSubTtl} value={docSubtitle}/>
                {/* <br/>
                <LimitInput ph={"Summary"} lim={255} oC={updSubTtl} val={docSummary}/> */}
                <Cre.CreateBody 
                    imgBudgetProps={{
                        totalSize: docFiles.current._info.totalSize,
                        maxSize: docFiles.current._info.maxCap
                    }}
                    formBodyProps={{
                        Data: docBody,
                        SetData: setBody,
                        fileAPI: fileAPI,
                        rV: RenderV,
                        BKey: baseKey
                    }}
                />
                <div className='Create-Buffer'/>

            </main>
            <div ref={docContainerRef} className={`draft-Preview ${previewActive? 'draft-Preview-Active' : ''}`}>
                <Document doc={{render: RenderV, bannerSrc: docBanner, title: docTitle, subtitle: docSubtitle, body: docBody, author: Auth.User}} inCreate={true}/>
                <div className='Create-Buffer'/>
            </div>
            <div className='Draft-SubHdr-Wrapper'>
                <button className='Draft-SubHdr-Btn DraftSH-Publish-Btn' onClick={() => setPublishing(true)}>
                    <BookIcon className='SubHdr-Btn-Icon'/>
                    <span>publish</span>
                    </button>
                <button className='Draft-SubHdr-Btn DraftSH-Save-Btn' onClick={() => saveDraft()}>
                    <SaveIcon className='SubHdr-Btn-Icon'/>
                    <span>save draft</span>
                    </button>
                <button className='Draft-SubHdr-Btn DraftSH-Preview-Btn' onClick={togglePreview}>
                    <EyeIcon className='SubHdr-Btn-Icon'/>
                    <span>preview</span>
                    </button>
            </div>
            { loading && <Cre.LoadOnFetch loading={loading}/> }
        </div>
        <Cre.PublishDialogue closeDisplay={() => setPublishing(false)} publishDraft={publishDraft} publishing={publishing}/>
        </>
    )
}

export default Create;