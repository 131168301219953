import { Repeat, EmptyP } from '../../../Misc-Components';
import '../styling/document.css'
import './styling/banner.css'
import './styling/author.css'
import '../../../User-Components/styling/userpfp.css'

import './styling/emptyDoc.css'

const EmptyDoc = () => {
    return (
        <div className='Doc'>
            <div className="Empty Doc-Grid">
                <div className='Empty doc-Banner-Placeholder'/>
                <div className='Doc-Content'>
                    <div className='Empty HeadHeader'/>
                    <div className='Empty HeadSubHeader'/>
                    <div className='Empty Authorship'>
                        <div className='Empty userpfp userpfp-SM softEdge'/>
                        <div className='Empty username'/>
                    </div>
                    <hr/>
                    <Repeat times={2}>
                        <EmptyP />
                    </Repeat>
                    <br/><br/>
                    <div className='Empty TimePosted'>
                        <div className='Empty Time'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyDoc;