import { useContext } from "react";
import { APIContext } from "../../layers";
import { Link } from "react-router-dom";
import {ReactComponent as PlusCircleSVG} from '../../icons/svg/plus-circle.svg';
import SmallCard from './userSmallCard';
import { simplifyNum } from "../Misc-Components";
import './styling/Follows.css'
import Userpfp from "./userpfp";

const Followers = ({username, followers, visitorInfo, FollowButton}) => {
    const API = useContext(APIContext);

    let moreFollows = 0;
    if(followers) {
        const {value, denom} = simplifyNum(followers.count - 4);
        moreFollows = `${value}${denom}`;
    }

    return(
        <div className="FllwTemplate">
            <div className="FllwTemplate-Header">
                <h2>Followers</h2>
                {FollowButton}
            </div>
            <div className="FllwTemplate-Body">
                {!followers && <div className='FllwTemplate-User FllwTemplate-User-Placeholder animated' /> }
                {followers?.users.slice(0,4).map((v,i) => {
                    const {_id, username, displayname} = v.follower;
                    return (<div key={username} className='FllwTemplate-User'>
                                <SmallCard userId={_id} avatarURL={API.getUserAvatar(username)} username={username} displayname={displayname}/>
                            </div>)
                })}
                {/* <br/> */}
                {followers && !followers.users.length &&  
                <div className="FllwTemplate-Body-Empty">
                    {visitorInfo.isUser? "No followers yet" : "Be their first follower?"}
                </div>
            }
            </div>
            {
                followers && followers.count > 4
                &&
                <Link className='FllwTemplate-More' to="#Followers">
                    <div className="Stack">
                        <div className="Plus">
                            <PlusCircleSVG style={{width: "100%", height: "100%"}}/>
                        </div>
                        {followers?.users.slice(4, 8).map((v,i) => {
                            const {_id, username} = v.follower;
                            return <Userpfp key={_id} userId={_id} userURL={API.getUserAvatar(username)} style={{height: '1.5rem', width: '1.5rem', zIndex: `-${i}`}}/>
                        })}
                    </div>
                    <div className="More">{moreFollows} more</div>
                </Link>
            }
        </div>
    )
}

export default Followers;