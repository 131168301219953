import { useReducer, useRef, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import { timeSince } from '../Misc-Components';
import { APIContext, NotifContext } from '../../layers';
import {ReactComponent as DotsSVG} from "../../icons/svg/three-dots-line.svg"
import {ReactComponent as TrashSVG} from "../../icons/svg/trash-alt.svg"
import './styling/draftCard.css'

function draftReducer(state, action) {
    switch(action.type) {
        case "MENU_TOGGLE":
            return {...state, menuOpen: !state.menuOpen};
        case "DELETE": 
            return {menuOpen: false, deletingDraft: true};
        case "DELETE_SUCCESS":
        case "DELETE_FAILED":
            return {...state, deletingDraft: false};
        default:
            return {menuOpen: false, deletingDraft: false};
    }
}

const DraftCard = ({
    draft = {title: "Untitled", subtitle: "", lastUpdated: null, _id: "#"}, 
    bannerSrc="", 
    clearDraft = () => {},
}) => {
    const API = useContext(APIContext);
    const Notif = useContext(NotifContext);
    const {title, subtitle, lastUpdated} = draft;
    const [cardState, dispatchCard] = useReducer(draftReducer, {
        menuOut: false,
        deletingDraft: false
    })
    const miscRef = useRef({});

    useEffect(() => {
        const controller = miscRef.current.abortController;
        return () => {
            controller?.abort();
        }
    }, []);

    /*
    TODO:
        - ClearDraft function
        - DraftCollection width 
    */

    function deleteDraft(e) {
        //Notif -> Deleting Draft [Loading]
        if(cardState.deletingDraft) return;
        
        miscRef.current.abortController?.abort();
        miscRef.current.abortController = new AbortController();
        const signal = miscRef.current.abortController.signal;

        dispatchCard({type: "DELETE"});
        const [finishNotif, abortNotif] = Notif.createNotif(['load'], "", "Deleting Draft...")
        API.deleteDraft(draft._id, {signal})
        .then(() => {
            finishNotif("success", "Draft deleted.");
            dispatchCard({type: "DELETE_SUCCESS"});
            clearDraft();
        })
        .catch((err) => {
            if(err instanceof DOMException && err.name === "AbortError"){
                abortNotif();
                return;
            }
            // err.response.json if you wanted to
            finishNotif("failure", "Draft deletion failed.");
            dispatchCard({type: "DELETE_FAILED"});
        });
    }
    
    return(
        <div className="DraftCard" style={{"--bannerSrc": bannerSrc}}>
            <Link to={`/create/${draft._id}`}>
                {bannerSrc && <div className='Banner'/>}
                <div className='Main'>
                    <div className='Title'>{title.length? title : "Untitled"}&nbsp;</div>
                    <div className='SubTitle'>{subtitle}&nbsp;</div>
                    <div className='Info'>
                        Last Updated: {timeSince(lastUpdated, Date.now())}
                    </div>
                </div>
            </Link>
            <div className='Options'>
                <button onClick={() => dispatchCard({type: "MENU_TOGGLE"})}>
                    <DotsSVG/>
                </button>
            </div>
            <div className={`Menu ${cardState.menuOpen? "open" : ""}`}>
                <button onClick={deleteDraft} tabIndex={cardState.menuOpen? 0 : -1}>
                    <TrashSVG style={{height: "100%"}}/> <span>Delete</span>
                </button>
            </div>
        </div>
    )
}

export default DraftCard;