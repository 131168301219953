import { pastel } from '../Misc-Components/pastel';
import './styling/userpfp.css'

const Userpfp = ({userId, userURL, sz='M', disableQuery=false, softPFP=true, style={}}) => {
    // X-Small (XS), Small (S), Smaller Medium (SM), Medium (M), Large (L), X-Large (XL)
    let className = `userpfp userpfp-${sz} ${softPFP? 'softEdge' : 'sharpEdge'}`;

    const src = userURL
                    ? (disableQuery? userURL : `${userURL}&sz=${sz}`) 
                    : '';
    const _style = {
        backgroundImage: `url(${src}), url("/userpfp_default.svg")`,
    };

    if(userId)
        _style.backgroundColor = pastel(userId);
    
    return (
        <div className={className} style={{..._style, ...style}}/>
    )
}

export default Userpfp;