import { createContext, useContext, useState, useRef} from "react";
import { APIContext, HttpError } from "./api";
import { NotifContext } from "./notif";

const AuthContext = createContext();

const AuthLayer = ({children}) => {
    const Notif = useContext(NotifContext);
    const API = useContext(APIContext);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const authRef = useRef({
        promise: null
    });

    /*
    user:
        - displayname: string
        - username: string
        - _id: ObjectId
        - isAdmin: boolean
        - dateJoined: Date
        - bio: string
        - lastUsernameChange: Date
    */

    async function refresh() {
        authRef.current.promise ??= new Promise((resolve, reject) => {
            API.refresh()
            .then(res => {
                if(![200, 429].includes(res.status))
                    throw new Error();
                authRef.current.lastRefresh = Date.now();
            })
            .then(resolve)
            .catch(() => {
                reject();
                logout();
            })
            .finally(() => {
                setTimeout(() => {
                    authRef.current.promise = null;
                }, 5000);
            })
        });
        return authRef.current.promise
    }

    async function __request__(cb) {
        return cb().then(res => {
            if(res.status === 401) {
                return refresh()
                .then(() => {
                    return cb();
                })
                .catch(() => res);
            }
            else
                return res;
        });
    }

    function logout(autoNav = true) {
        API.logout();
        
        if(!user)
            return;
        localStorage.removeItem('user');
        setUser(null); 
        Notif.createNotif([], "", "You have been logged out.")
        if(autoNav)
            window.location = "/login"
    }
    // ==================
    async function request(cb){
        if(!user)
            return {status: 401, errors: {auth: 'Not logged in.'}};
        return await __request__(cb);
    }
    // ==================

    async function requestNewDraft(opts) {
        return request(() => API.requestNewDraft(opts))
        .then(res => {
            if(res.status === 201)
                return res.json();
            throw new HttpError(res);
        });
    }
    async function getDrafts(opts) {
        return request(() => API.getDrafts(opts))
        .then(res => {
            if(res.status === 200)
                return res.json();
            throw new HttpError(res);
        });
    }
    async function getDraft(draftID) {return await request(() => API.getDraft(draftID));}
    async function deleteDraft(draftID, opts={}) {
        return request(() => API.deleteDraft(draftID, opts))
        .then(res => {
            if(res.status === 200)
                return res; // no json
            throw new HttpError(res);
        });
    }
    async function saveDraft(draftID, formdata) {return await request(() => API.saveDraft(draftID, formdata));}
    async function publishDraft(draftID) {return await request(() => API.publishDraft(draftID));}
    async function registerUser(formData, opts = {}) {
        return API.registerUser(formData, opts)
        .then(res => {
            if(res.status === 201)
                return res.json();
            throw new HttpError(res);
        })
        .then(json => {
            localStorage.setItem('user', JSON.stringify(json.user));
            setUser(json.user);
            authRef.current.lastRefresh = Date.now();
            return json;
        });
        }
    async function loginUser(formData, opts = {}) {
        return API.loginUser(formData, opts)
        .then(res => {
            if(res.status === 200)
                return res.json();
            throw new HttpError(res);
        }).then(json => {
            localStorage.setItem('user', JSON.stringify(json.user));
            setUser(json.user);
            authRef.current.lastRefresh = Date.now();
            return json;
        });        
        }
    async function changeAvatar(formdata) {
        if(!formdata)
            return {errMsg: ['No formdata provided to make request.']};

        return await request(() => API.changeAvatar(formdata));
        }
    async function changeUserBio(newBio){
        return await request(() => API.changeUserBio(newBio));
        }
    async function changeSettings(setting, body){
        return await request(() => API.changeSettings(setting, body));
    }
    async function followUser(targetUser, action){
        if(!user)
            throw new Error('Must be signed into an account to follow users.');
            // throw new Error({status: 401, errors: {auth: 'Must be signed into an account to follow users.'}});

        return await request(() => API.followUser(targetUser, action))
        .then(res => {
            if(res.status === 200)
                return res;
            throw new HttpError(res);
        });
        }
    async function getHomeFeed(page){
        return await API.getHomeFeed(page);
    }
    async function getUser(username, opts) {
        if(!user)
            return API.getUser(username, opts)
            .then(res => {
                if(!res.ok)
                    throw new HttpError(res);
                return res.json();
            })

        return await request(() => API.getUser(username, opts))
        .then(res => {
            if(!res.ok)
                throw new HttpError(res);
            return res.json();
        })
    }

    const AuthAPI = {
        ...API,
        requestNewDraft,
        getDrafts,
        getDraft,
        deleteDraft,
        saveDraft,
        publishDraft,
        registerUser,
        loginUser,
        changeAvatar,
        changeUserBio,
        changeSettings,
        followUser,
        getHomeFeed,
        getUser,
    }

    const Auth = {
        User: user,
        logout,
    }

    return (
        <APIContext.Provider value={AuthAPI}>
        <AuthContext.Provider value={Auth}>
            {children}
        </AuthContext.Provider>
        </APIContext.Provider>
    )
}

export default AuthLayer;
export {
    AuthContext
}