import './styling/document.css'
import {
    Banner, Body, Author, EmptyDoc
} from './sub-components'
import { getFullDate } from '../../Misc-Components';

const Document = ({doc, inCreate=false}) => {
    if(!doc)
        return (<EmptyDoc/>)
    const {render, bannerSrc, title, subtitle, body, author, timeCreated} = doc;

    return (
        <div className='Doc'>
            <div className='Doc-Grid'>
            <Banner src={bannerSrc}/>
            <div className='Doc-Content'>
                <h1 className='HeadHeader'>{title}</h1>
                <h3 className='HeadSubHeader'>{subtitle}</h3>
                <Author user={author} haveLink={!inCreate} date={timeCreated}/>
                <hr/>
                <Body rV={render} body={body}/>
                <br/><br/>
                {timeCreated && 
                    <p className='TimePosted'>
                        <em>
                        {getFullDate(timeCreated)}
                        </em>
                    </p>
                }
            </div>
            </div>
        </div>
    )
}

export default Document;