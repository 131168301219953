/* Attribution to these StackOverflow questions and answers:
    https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
    https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
*/

export function hashCode(str) {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    })
    return hash;
}

/*
    cyrb53 (c) 2018 bryc (github.com/bryc)
    License: Public domain (or MIT if needed). Attribution appreciated.
    A fast and simple 53-bit string hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
*/
export function cyrb53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for(let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export function pastelHsl(id) {
    if(!id)
        id = "0";
    let hash = cyrb53(id);
    const oneDecimals = (num) => (Math.floor(num * 100) / 100).toFixed(1);
    const hue = oneDecimals( 
            360 * ((hash & 0xFF) / 0xFF)
        );
    hash = hash >> 8;
    const saturation = oneDecimals(
        25 + 60 * ((hash & 0xFF) / 0xFF)
        ); 
    hash = hash >> 8;
    const lightness = oneDecimals(
        70 + 15 * ((hash & 0xFF) / 0xFF)
        );
    
    return [hue,saturation,lightness];
}

export function pastel(id) {
    const [hue, saturation, lightness] = pastelHsl(id);
    return `hsl(${hue},${saturation}%,${lightness}%)`;
}