import { Helmet } from "react-helmet";
import { Repeat, Skeleton, EmptyP } from "../Misc-Components";
import './styling/userSkeleton.css';

const UserSkeleton = ({username = ""}) => {

    return (
        <div id='User' className="Skeleton">
            <Helmet titleTemplate='@%s - UrbanCoffee.io'>
                <title>{username ?? "..."}</title>
            </Helmet>
            <div className="User-Container">
                <main>
                    <div className="UserBanner"/>
                    {/* User Display */}
                    <div className="UserDisplay">
                        <div className="UD-PFP" />
                        <div className="UD-Info">
                            <Skeleton varWidth bias={15} range={85}>
                                <div className="UD-Displayname">
                                        <h1>Hello</h1>
                                </div>
                            </Skeleton>
                            <Skeleton varWidth bias={5} range={45}>
                                <div className="UD-Username"><p>@Urban</p></div>
                            </Skeleton>
                        </div>
                    </div>
                    {/* User Content */}
                    <div className="User-Content">
                        {/* Small Width */}
                        <div className="SmallWidth">

                        </div>
                        {/* User Stats */}
                        <div className="UserStats-Container">
                            {/* <Repeat times={5}>
                                <div className="US-Stat">
                                    <Skeleton style={{justifySelf: "center"}}>
                                        <h3>fillerfiller</h3>
                                    </Skeleton>
                                    <Skeleton style={{justifySelf: "center", width: "75%"}}>
                                        <h1>0</h1>
                                    </Skeleton>
                                </div>
                            </Repeat> */}
                        </div>
                        {/* Selections */}
                        {/* Content Container */}
                    </div>
                </main>

                <aside>
                    {/* About */}
                    <div className="UserAbout">
                        <Skeleton>
                            <h2>About</h2>
                        </Skeleton>
                        <EmptyP style={{marginTop: "1rem"}}/>
                    </div>
                    <Repeat times={2}>
                        {/* Aside Following */}
                        {/* Aside Followers */}
                        <div className="FllwTemplate">
                            <div className="FllwTemplate-Header">
                                <Skeleton>
                                    <h2>Followers</h2>
                                </Skeleton>
                            </div>
                            <div className="FllwTemplate-Body">
                                <div className="FllwTemplate-User FllwTemplate-User-Placeholder"/>
                            </div>
                        </div>
                    </Repeat>
                </aside>
            </div>
        </div>
    )

}

export default UserSkeleton;
