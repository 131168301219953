import "./styling/404.css"

const PageNotFound = ({path}) => {
    return(
        <div id='p404'>
            <h1><span>4</span><span>0</span><span>4</span></h1>
            <p>Page <b>{path}</b> not found or recognized</p>
        </div>
    )
}

export default PageNotFound;