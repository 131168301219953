import { useContext, useEffect, useRef, useReducer } from "react";
import { APIContext, NotifContext } from "../layers";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {ReactComponent as UrbanCoffeeLogo} from "../icons/svg/urbcof_logo.svg"
import './styling/AccountAccess/AccountAccess.css'
import './styling/AccountAccess/LogIn.css'
import { HttpError } from "../layers/api";

function loginReducer(state, action) {
    switch(action.type) {
        case "LOGIN":
            return {loading: true, error: null};
        case "ERROR":
            return {loading: false, error: action.value};
        default:
            return {loading: false, error: null};
    }
}

const Login = () => {
    const API = useContext(APIContext);
    const Notif = useContext(NotifContext);
    const signalRef = useRef({});
    const navigate = useNavigate();
    const [loginState, dispatchLoginState] = useReducer(loginReducer, {
        loading: false,
        error: null
    });

    async function handleSubmit(e){
        e.preventDefault();

        if(signalRef.current.abortSignal)
            signalRef.current.abortSignal.abort();

        const formdata = Object.fromEntries(new FormData(e.target));

        signalRef.current.abortSignal = new AbortController();
        const signal = signalRef.current.abortSignal.signal;
        dispatchLoginState({type: "LOGIN"});

        API.loginUser(formdata, {signal})
        .then(json => {
            Notif.createNotif([], null, `Logged in as @${json.user.username}.`)
            navigate('/');
        })
        .catch(err => {
            if(err instanceof DOMException && err.name === "AbortError") {
                dispatchLoginState({});
                return;
            }

            if(!(err instanceof HttpError)) {
                dispatchLoginState({type: "ERROR", value: "An error occured."});
                return;
            }

            let msg;
            const status = err.response.status;
            if(500 <= status) {
                msg = `Server encountered an issue (E${status}). Try again.`;
            } else if(200 !== status && status < 400) {
                msg = `Unexpected server response (E${status}). Try again later.`;
            }
            else switch(status) {
                case 429: 
                    msg = "Too many login attempts."; break;
                case 401: 
                    msg = "Invalid Username and/or Password"; break;
                default: 
                    msg = `Invalid Login Request (E${status})`; break;
            }
            dispatchLoginState({type: "ERROR", value: msg});
        })
    }

    useEffect(() => {
        const abortSignal = signalRef.current.abortSignal;
        return () => {
            if(abortSignal)
                abortSignal.abort();
        }
    }, [])

    return (
        <div id='Login' className='AccountAccess'>
            <Helmet>
                <title>Log In - UrbanCoffee.io</title>
                <meta name="description" content="Log in to your account here."/>
            </Helmet>
            <div className='AA-Container'>
                <div className="TitleSection">
                    <div className="LogoContainer">
                        <UrbanCoffeeLogo/>
                    </div>
                </div>
                {/* <div className='AA-Header'>
                    <h2>Log In</h2>
                </div> */}
                <div className='AA-Content'>
                        <p className={`P-Error ${loginState.error? "" : "empty"}`}>
                            &nbsp;
                        {
                            loginState.error &&
                            <>{loginState.error}</>
                        }
                        </p>
                    <form onSubmit={handleSubmit} className='AA-Form'>
                        <label htmlFor="username">Username</label><br/>
                        <input type="text" name="username" id="username" autoComplete="username" required/>
                        <br/> <br/>

                        <label htmlFor="password">Password</label><br/>
                        <input type="password" name="password" id="password" autoComplete="current-password" required/>
                        <br/>

                        <div className="AA-Btn-Wrapper">
                            <button type="submit" disabled={loginState.loading}>{loginState.loading? <span/>: "Log In"}</button>
                        </div>
                    </form>

                    <p>    
                        Don't have an account? <Link to="/signup">Create one.</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login;