import { Link } from 'react-router-dom';
import { pastel } from '../Misc-Components/pastel';
import './styling/navbtn.css'

const BORDER_WIDTH = '5px';

const NavLink = ({to = "#", icon, title, padding='15', visibleTitle=false, adminBtn=false}) => {
    let paddingStrength = parseInt(padding);
    if(!isNaN(paddingStrength)){
        if (paddingStrength < 0)
            paddingStrength = 0;
        else if (paddingStrength > 100) 
            paddingStrength = 100;
    }
    else {
        paddingStrength = 0
    }

    const style = {
        padding: `${paddingStrength}%`
    }
    if(adminBtn)
        style.paddingLeft = `calc(${style.padding} - ${BORDER_WIDTH})`;

    return(
        <Link className={`NavLink ${adminBtn? 'adminBtn':''}`} to={to} style={style}>
            {icon && 
                <BtnIcon>{icon}</BtnIcon>
            }
            {title && 
                <IconTitle alwaysVisible={visibleTitle}>{title}</IconTitle>
            }
        </Link>
    )
}

const UserNavLink = ({User, UserAvatar}) => {
    const style = {
        backgroundColor: pastel(User._id),
        backgroundImage: `url(${UserAvatar}), url("/userpfp_default.svg")`
    }
    return(
        <Link className='NavLink NavLink-User' to={`/@${encodeURIComponent(User.username)}`} style={style} />
    )
}

const HamMenuOpt = ({children, onClick, className = "", style}) => {
    return (
        <div className={`CNav-HamMenu-Opt ${className}`} onClick={onClick} style={style}>
            {children}
        </div>
    )
}

const UserHamMenuOpt = ({User, UserAvatar, onClick}) => {

    const style = {
        backgroundColor: pastel(User._id),
        backgroundImage: `url(${UserAvatar}), url("/userpfp_default.svg")`
    }

    return (
        <div className='CNav-HamMenu-Opt' onClick={onClick}>
            <div className='CNav-HamMenu-Opt-UserAvt' style={style}/>
            <span>{User.username}</span>
        </div>
    )
}

const IconTitle = ({children, alwaysVisible=false}) => {
    return (
        <div className={`NL-IconTitle ${alwaysVisible?'visibleTitle':''}`}>{children}</div>
    )
}

const BtnIcon = ({children}) => {
    return (
        <div className='NL-Icon'>{children}</div>
    )
}
export default NavLink;
export {UserNavLink, IconTitle, BtnIcon, HamMenuOpt, UserHamMenuOpt};