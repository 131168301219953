import { simplifyNum } from "../Misc-Components";
import "./styling/userStats.css";

const Stat = ({label, count}) => {
    const {value, denom} = simplifyNum(count);

    return(
        <div className='US-Stat'>
            <h3>{label}</h3>
            <h1>{value}<span>{denom}</span></h1>
        </div>
    )
}

const UserStats = ({user}) => {
    return (
        <div className="UserStats-Container">
            <Stat label='Documents'   count={user.docsPublished ?? 0}/>
            <Stat label='Followers'   count={user.followers.count ?? 0}/>
            <Stat label='Following'   count={user.following.count ?? 0}/>
            <Stat label='Collections' count={0}/>
            <Stat label='Statuses'    count={0}/>
        </div>
    )
} 

export default UserStats;