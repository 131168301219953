import { useRef, useContext, useReducer, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { NotifContext, APIContext } from "../layers/"
import { HttpError } from "../layers/api";
import LimitInput from "../components/Misc-Components/limit_input";
import {ReactComponent as ExclamationMark} from "../icons/svg/exclamation_mark.svg"
import {ReactComponent as ErrorX} from "../icons/svg/error_x.svg"
import {ReactComponent as Checkmark} from "../icons/svg/checkmark.svg"
import {ReactComponent as QuestionMark} from "../icons/svg/question_mark.svg"
import './styling/AccountAccess/AccountAccess.css'
import './styling/AccountAccess/SignUp.css'

const minLength_Displayname = 1;
const maxLength_Displayname = 20;
const minLength_Username = 1;
const maxLength_Username = 15;

function processDisplayname(displayname) {
    return displayname.trimLeft().replaceAll(/\s{2,}/g, " ");
}

function processUsername(username) {
    return username.trimLeft().replaceAll(/\s+/g, "").toLowerCase();
}

function validateDisplayname(displayname) {
    return !(
        displayname.length < minLength_Displayname
        || /[^ \w._-]/.test(displayname)
    );
}

function validUsername(username) {
    return !(
        username.length < minLength_Username
        || /[^a-z0-9._-]/.test(username)
        || /[._-]{2,}/.test(username)
    );
}

function FormReducer(state, action) {
    const NewState = {...state};
    switch(action.type) {
        case "DISPLAYNAME_ONCHANGE": 
            NewState.displayname = processDisplayname(action.value);
            NewState.validDisplayName = validateDisplayname(NewState.displayname);
            if(NewState.validDisplayName)
                NewState.server_errors.displayname = null;
            break;
        case "DISPLAYNAME_ONBLUR": break;
        case "USERNAME_ONCHANGE": 
            NewState.username = processUsername(action.value);
            NewState.validUsername = validUsername(NewState.username);
            // username server error cleared in usernameReducer when in VALID state
            break;
        case "USERNAME_ONBLUR": break;
        case "PASSWORD_ONCHANGE": 
            NewState.password = action.value;
            NewState.validPassword = NewState.password.length >= 8;
            if(NewState.validPassword)
                NewState.server_errors.password = null;
            break;
        case "PASSWORD_ONBLUR": 
            break;

        case "EMAIL":
            // currently not required
            return state;

        case "SIGNUPCODE_ONCHANGE": 
            NewState.validSignupCode = action.value.length > 0; // kinda of a lie. HTML requirements will tell user that seven is needed
            NewState.server_errors.signupcode = null;
            break;
        case "SIGNUPCODE_ONBLUR":
            break;

        case "LOADING_TOGGLE":
            NewState.loading = !NewState.loading;
            break;
        case "ERROR":
            NewState.loading = false;
            // clear old errors
            for(const key in NewState.server_errors) {
                NewState.server_errors[key] = null;
            }
            // set new errors
            for(const key in action.value) {
                console.log(key, action.value[key]);
                NewState.server_errors[key] = action.value[key];
            }

            break;
        
        default: 
            return state;
    }
    NewState.validForm = NewState.validDisplayName
                         && NewState.validUsername
                         && NewState.validPassword
                         && NewState.validEmail
                         && NewState.validSignupCode; 
    return NewState;
}

function UsernameReducer(state, action) {
    const NewState = {
        ...state,
        currentUsername: action.value,
        state: action.type
    };

    switch(action.type) {
        case "EMPTY": NewState.message = <>No username</>; break;
        case "INVALID": NewState.message = <><b>{NewState.currentUsername}</b> is invalid</>; break;
        case "WAITING": NewState.message = <><b>{NewState.currentUsername}</b> is...</>; break;
        case "SENT": NewState.message = <><b>{NewState.currentUsername}</b> is...</>; break;
        case "TAKEN": NewState.message = <><b>{NewState.currentUsername}</b> is taken</>; break;
        case "VALID": NewState.message = <><b>{NewState.currentUsername}</b> is available</>; break;
        default:
            NewState.message = `Encountered an error (E${action.code})`; break;
    }
    return NewState;
}

const Signup = () => {
    const Notif = useContext(NotifContext);
    const API = useContext(APIContext);
    const [formState, dispatchFormState] = useReducer(FormReducer, {
        displayname: "",
        username: "",
        password: "",
        email: null,
        signupCode: "",

        validDisplayName: false,
        validUsername: false,
        validPassword: false,
        validEmail: true, // doesn't matter for now
        validSignupCode: false,
        validForm: false,

        loading: false,
        server_errors: {
            displayname: null,
            username: null,
            password: null,
            email: null,
            signupcode: null,
            general: null
        }
    });
    const [usernameState, dispatchUsernameState] = useReducer(UsernameReducer, {
        currentUsername: "",
        state: "EMPTY",
        message: "No username",
    });
    const miscRef = useRef({registerController: null, usernameController: null, debounceTimer: null}); 
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        // form checks
        if(!formState.validForm || usernameState.state !== "VALID") {
            dispatchFormState({
                type: "ERROR", 
                value: {
                    general: "Make sure each required entry is valid then try again.",
                    username: (usernameState.state !== "VALID"
                        ? <>Please ensure that username is <b>valid and available.</b></>
                        : null
                    )
                }
            });
            return;
        }
        // end form checks

        const formdata = new FormData(e.target);
        if(miscRef.current?.registerController)
            miscRef.current.registerController.abort();

        miscRef.current.registerController = new AbortController();
        const signal = miscRef.current.registerController.signal;

        dispatchFormState({type: "LOADING_TOGGLE"});
        API.registerUser(formdata, {signal})
            .then( res => {
                Notif.createNotif([], <>Welcome <b>@{res.user.username}</b>!</>, "You have successfully created an account.");
                navigate(`/@${encodeURIComponent(res.user.username)}`);
            })
            .catch(async err => {
                if(err instanceof DOMException && err.name === "AbortError") {
                    dispatchFormState({type: "LOADING_TOGGLE"});
                    return;
                }
                
                try {
                    if(!(err instanceof HttpError))
                        throw new Error();
                    
                    const json = await err.response.json();
                    dispatchFormState({type: "ERROR", value: json.errors})
                } catch(e) {
                    console.group("Error");
                        console.error(err);
                        console.error(e);
                    console.groupEnd();

                    dispatchFormState({
                        type: "ERROR", 
                        value: {
                            general: `Unexpected error was encountered. See console log for details.`
                        }
                    })
                    return;
                }
            });
    }

    function userDebounce(username) {
        clearTimeout(miscRef.current.debounceTimer);
        if(!username.length) {
            dispatchUsernameState({type: "EMPTY", value: ""});
        } else if (!validUsername(username)) {
            dispatchUsernameState({type: "INVALID", value: username});
        } else {
            dispatchUsernameState({type: "WAITING", value: username});
            miscRef.current.debounceTimer = setTimeout(() => {
                dispatchUsernameState({type: "SENT", value: username});

                miscRef.current.usernameController = new AbortController();
                const usersignal = miscRef.current.usernameController.signal;

                API.userExists(`${username}`, usersignal)
                   .then((res) => {
                        switch(res.status) {
                            case 200: // Username taken 
                                dispatchUsernameState({type: "TAKEN", value: username});
                                break;
                            case 400: // Invalid Username
                                dispatchUsernameState({type: "INVALID", value: username});
                                break;
                            case 404: // Username available (user not found)
                                dispatchUsernameState({type: "VALID", value: username});
                                // clear username error (if any)
                                dispatchFormState({type: "ERROR", value: {...formState.server_errors, username: null}})
                                break;
                            default: // Something else happened
                                dispatchUsernameState({type: "ERROR", value: username, code: res.status});
                                break;
                        }
                   })
                   .catch(err => {
                        if(err instanceof DOMException && err.name === "AbortError")
                            return;
                    })
            }, 1000);
        }
    }

    function handleUsernameOnChange(e) {
        const username = processUsername(e.target.value);
        if( 
            username === usernameState.currentUsername
            || formState.loading
        ) return;
        
        miscRef.current.usernameController?.abort();

        dispatchFormState({type: "USERNAME_ONCHANGE", value: username});
        userDebounce(username);
    }

    useEffect(() => {
        const obj = miscRef.current;
        return () => {
            if(!obj)
                return;

            const {registerController, usernameController} = (obj ?? {});
            usernameController?.abort();
            registerController?.abort();
        }
    }, []);

    return (
        <div id='Signup' className='AccountAccess'>
        <Helmet>
            <title>Sign Up - UrbanCoffee.io</title>
            <meta name="description" content="Create an account."/>
        </Helmet>
        <div className='AA-Container'>
            <div className='AA-Header'>
                <h2>Create An Account</h2>
            </div>
            <div className='AA-Content'>
                    <p className={`P-Error ${formState.server_errors.general? '' : 'empty'}`}>
                    &nbsp;{formState.server_errors.general}
                    </p>
                <form onSubmit={handleSubmit} className='AA-Form'>
                    <div style={{display: "grid", rowGap: ".5rem", marginBottom: "2em"}}>
                        <div>    
                            <label htmlFor="displayname">Display Name</label>
                            <p className={`P-Error inline ${formState.server_errors.displayname? '' :'empty'}`}>
                                &nbsp;{formState.server_errors.displayname}
                            </p>
                            <br/>
                            <LimitInput lim={maxLength_Displayname} 
                                name="displayname" id="displayname"
                                onBlur={(val) => dispatchFormState({type: "DISPLAYNAME_ONBLUR", value: val})}
                                onChange={(e) => dispatchFormState({type: "DISPLAYNAME_ONCHANGE", value: e.target.value})}
                                value={formState.displayname}
                                className="signup-LimitInput"
                                disableSpellCheck={true}
                                hasError={formState.displayname.length > 0 && !formState.validDisplayName}
                                disabled={formState.loading}
                                autoComplete="name"
                                required
                                />
                            <div className="requirements">
                                <span className={formState.displayname.length === 0 || formState.validDisplayName? "" : "invalid"}>
                                    &bull; Only Alphanumeric, hyphen, periods, spaces, and underscores allowed. <br/>
                                </span>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="username">Username</label>
                            <p className={`P-Error inline ${formState.server_errors.username? '' : 'empty'}`}>
                                &nbsp;{formState.server_errors.username}
                            </p>
                            <br/>
                            <LimitInput lim={maxLength_Username} 
                                name="username" id="username"
                                onBlur={(val) => dispatchFormState({type: "USERNAME_ONBLUR", value: val})}
                                onChange={handleUsernameOnChange}
                                value={formState.username}
                                className="signup-LimitInput"
                                disableSpellCheck={true}
                                hasError={["INVALID", "TAKEN"].includes(usernameState.state)}
                                disabled={formState.loading}
                                autoComplete="username"
                                required
                                />
                            <div className="Availability-Checker">
                                { usernameState.state === "EMPTY" &&
                                <div className="username-checker-indicator"><ErrorX/></div>
                                }

                                { usernameState.state === "WAITING" &&     
                                <div className="username-checker-indicator spinning waiting"><ErrorX/></div>
                                }

                                { usernameState.state === "SENT" &&
                                <div className="username-checker-indicator spinning"><ErrorX/></div>
                                }

                                { ["INVALID", "TAKEN"].includes(usernameState.state) &&
                                <div className="username-checker-indicator invalid"><ErrorX/></div>
                                }
                                
                                { usernameState.state === "VALID" &&
                                <div className="username-checker-indicator valid"><Checkmark/></div>
                                }  

                                { usernameState.state === "ERROR" &&
                                <div className="username-checker-indicator invalid"><QuestionMark style={{padding:".075rem"}}/></div>
                                }  

                                <span style={{marginLeft: ".25rem"}}>{usernameState.message}</span>                          
                            </div>
                            <div className="requirements">
                                <span className={!/[^a-z0-9._-]/.test(usernameState.currentUsername)? "" : "invalid"}>
                                    &bull; Only alphanumeric, hyphen, periods, and/or underscores. <br/>
                                </span>

                                <span className={!/[._-]{2,}/.test(usernameState.currentUsername)? "" : "invalid"}>
                                    &bull; No consecutive hyphens, periods or underscores.
                                </span>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password">Password</label>
                            <p className={`P-Error inline ${formState.server_errors.password? '' : 'empty'}`}>
                                &nbsp;{formState.server_errors.password}
                            </p>
                            <br/>
                            <input 
                                className={0 < formState.password.length && formState.password.length < 8 ? "invalid" : ""}
                                type="password" name="password" id="password" 
                                minLength="8" 
                                onChange={(e) => dispatchFormState({type: "PASSWORD_ONCHANGE", value: e.target.value})}
                                value={formState.password}
                                disabled={formState.loading}
                                autoComplete="new-password"
                                required
                                />
                            <div className="requirements">
                                <span className={0 < formState.password.length && formState.password.length < 8 ? "invalid" : ""}>
                                    &bull; Passwords must be at least 8 characters long.
                                </span>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="email" style={{"opacity": ".45"}}>Email</label>
                            <span style={{display: "inline-flex", alignItems: "center", marginLeft: ".25rem", color: "#000B"}}>
                                <ExclamationMark style={{"width": ".8rem", "height":".8rem", "margin": ".1rem", "color": "#0009"}}/>
                                <span style={{"paddingLeft": ".1rem", "fontWeight": "500", "fontSize": ".8rem"}}>
                                    Emails <b>not</b> accepted at this time.
                                </span>
                            </span>
                            <br/>
                            <input type="email" name="email" id="email" style={{"opacity": ".45"}} disabled placeholder="example@urbancoffee.io"/>
                        </div>

                        <div>
                            <label htmlFor="signupcode" style={{"marginRight": "1em"}}>Sign-Up Code</label>
                            <input type="text" 
                                name="signupcode" id="signupcode"
                                autoComplete='one-time-code'
                                onChange={(e) => dispatchFormState({type: "SIGNUPCODE_ONCHANGE", value: e.target.value})}
                                minLength={7}
                                maxLength={7} 
                                disabled={formState.loading}
                                required
                                />
                            <div style={{marginBottom: ".25rem", "fontWeight": "500", "fontSize": ".8rem", color: "#000B"}}>
                                &bull; Email <a href="mailto:urbancoffee@proton.me" 
                                        style={{"color": "currentcolor", "fontWeight": "bold", "textDecoration": "none"}}>urbancoffee@proton.me</a> for a code.
                                    <br/>
                            </div>
                            <p className={`P-Error inline ${formState.server_errors.signupcode? '' : 'empty'}`}>
                                &nbsp;{formState.server_errors.signupcode}
                            </p>
                        </div>
                    </div>

                    <div className="AA-Btn-Wrapper">
                        <button type="submit" disabled={formState.loading || !formState.validForm || usernameState.state !== "VALID"}>
                            {formState.loading? <span/> : "Sign Up"}
                        </button>
                    </div>
                    <p>
                        Have an account? <Link to="/login">Log In.</Link>
                    </p>
                </form>

            </div>
        </div>
        </div>
    )
}

export default Signup;