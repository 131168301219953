import { useState, useReducer, useEffect, useContext, useRef } from 'react'
import { APIContext, NotifContext } from '../layers';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {ReactComponent as PlusCircleSVG} from "../icons/svg/plus-circle.svg"
import {ReactComponent as RetrySVG} from "../icons/svg/rotate-right.svg"
import { DraftCard } from '../components'
import './styling/DraftCollection.css'

import { HttpError } from '../layers/api';
import { useElementsOnScreen } from '../components/Misc-Components/useElementsOnScreen';

function NewDraft() {
    const API = useContext(APIContext);
    const Notif = useContext(NotifContext);
    const [loading, setLoading] = useState(false);
    const controllerRef = useRef({});
    const navigate = useNavigate();

    useEffect(() => {
        const controller = controllerRef.current?.abortController;
        return () => {
            controller?.abort();
        }
    }, [])

    const onClick = (e) => {
        if(loading)
            return;
        setLoading(true);

        controllerRef.current.abortController = new AbortController();
        const signal = controllerRef.current.abortController.signal;
        API.requestNewDraft({signal})
        .then(json => {
            console.log('JSON:', json);
            Notif.createNotif([], "", "Draft created");
            navigate(`/create/${json.draftId}`);
        })
        .catch(async err => {
            if(err instanceof DOMException && err.name === "AbortError")
                return;

            if(!(err instanceof HttpError)) {
                Notif.createNotif([], "Something went wrong", `Error: ${err.message}`);
            }

            try {
                const json = await err.response.json();
                Notif.createNotif(['error'], "Draft could not be created", `Reason: ${json.errors.draft ?? json.errors.token}`);
            } catch (e) {
                Notif.createNotif(['error'], "Draft could not be created", `Reason: ${err.message}`);
            }
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <button className='NewDraftCard' onClick={onClick}>
            <div className='PlusCircleContainer'>
                {<PlusCircleSVG className={loading? "svg-ouroboros-spinner" : ""} />}
            </div>
            New Draft
        </button>
    )
}

const MAX_PAGE_SZ = 10;

function draftsReducer(state, action) {
    const NewState = {...state};
    switch(action.type) {
        case "DRAFTS":
            NewState.drafts.push(...action.value);
            NewState.more = action.value.length >= MAX_PAGE_SZ;
            NewState.cursor = NewState.more && action.value.at(-1).lastUpdated;
            NewState.loading = false;
            NewState.failed = false;
            NewState.fetched = true;
            break;
        case "FILTER_DRAFT":
            NewState.drafts = NewState.drafts.filter(d => d._id !== action.value);
            break;
        case "LOADING":
            NewState.loading = true;
            NewState.failed = false;
            break;
        case "FAILED":
            NewState.loading = false;
            NewState.failed = true;
            break;
        case "CLEAR_LOADING":
            NewState.loading = false;
            NewState.failed = false;
            break;
        default:
            break;
    }
    return NewState;
}

const DraftCollection = () => {
    const API = useContext(APIContext);
    const [draftsState, dispatchDrafts] = useReducer(draftsReducer, {
        drafts: [],
        cursor: null,
        loading: false,
        failed: false,
        fetched: false,
        more: true
    });
    const [containerRef, isVisible] = useElementsOnScreen({
        root: null, 
        rootMargin: "0px", 
        threshold: "1"
    }, false);
    const miscRef = useRef({});

    useEffect(() => {
        const abortController = miscRef.current.abortController;
        return () => {
            abortController?.abort();
        }
    }, []);

    useEffect(() => {
        const {loading, failed, more, fetched} = draftsState;
        if((fetched && !isVisible) || !more || loading || failed)
            return;

        miscRef.current.abortController?.abort();
        miscRef.current.abortController = new AbortController();
        const signal = miscRef.current.abortController.signal;
        dispatchDrafts({type: "LOADING"});

        API.getDrafts({cursor: draftsState.cursor, signal})
        .then(res => {
            dispatchDrafts({type: "DRAFTS", value: res.drafts});
        })
        .catch((err) => {
            if(err instanceof DOMException && err.name === "AbortError") {
                dispatchDrafts({type: "CLEAR_LOADING"});
                return;
            }
            dispatchDrafts({type: "FAILED"});
        })

    }, [isVisible, API, draftsState]);

    return (
        <div id='Collection'>
            <Helmet>
                <title>Drafts - UrbanCoffee.io</title>
            </Helmet>
            <main>
                <header>
                <h1>Drafts</h1>
                </header>
                <div className='Collection-Drafts'>
                    <NewDraft />
                    <h2>Recent Drafts</h2>
                    {
                        draftsState.drafts.map(d => {
                            const clearDraft = () => dispatchDrafts({type: "FILTER_DRAFT", value: d._id});
                            const bannerSrc = d.hasBanner? `url("${API.getDraftImg(d._id, 'banner')}")` : null;
                            return <DraftCard key={d._id} draft={d} bannerSrc={bannerSrc} clearDraft={clearDraft}/>
                        })
                    }
                    <div style={{display: "grid", justifyItems: "center"}}>
                        {
                            !draftsState.more && draftsState.drafts.length === 0
                            && <div style={{color: "gray"}}><em>You have no drafts</em></div>
                        }
                        {
                            draftsState.more && !draftsState.loading && !draftsState.failed && draftsState.fetched 
                            && <div ref={containerRef}>&nbsp;</div> 
                        }
                        {
                            draftsState.loading 
                            && <div style={{width: "2rem", color: "var(--URBAN-GRAY"}}><div className='spinner delay-vis-spinner' /></div>}
                        {
                            draftsState.failed 
                            && 
                            <button className="retry-button" onClick={() => {dispatchDrafts({type: "CLEAR_LOADING"})}}>
                                <RetrySVG style={{width:"80%", height: "70%"}}/>
                                <span style={{position: "absolute", bottom: "0", left: "0", width: "100%", fontWeight: "600"}}>Retry</span>
                            </button>
                        }
                    </div>
                </div>
            </main>
        </div>
    )
}

export default DraftCollection;