import * as UserContent from "./SubRender";

// const _userSelections = ['Activity', 'Documents', 'Collections', 'Statuses', 'Users'];

function userSelection(hashPath) {
    if(hashPath === "") 
        return ["HOME", ""];
    if(/^#Activity(\/.*)?$/i.test(hashPath)) 
        return ["ACTIVITY", hashPath.replace(/^#Activity/i, "")];
    if(/^#Collections(\/.*)?$/i.test(hashPath)) 
        return ["COLLECTIONS", hashPath.replace(/^#Collections/i, "")];
    if(/#Statuses(\/.*)?$/.test(hashPath)) 
        return ["STATUSES", hashPath.replace(/^#Statuses/i, "")];
    if(/^#Followers(\/.*)?$/i.test(hashPath)) 
        return ["FOLLOWERS", hashPath.replace(/^#Followers/i, "")]; 
    if(/^#Following(\/.*)?$/i.test(hashPath)) 
        return ["FOLLOWING", hashPath.replace(/^#Following/i, "")];
    return [null, hashPath];
}

const UserRenderSelections = ({location, user, visitorInfo, dispatcher}) => {
    // console.group("RenderSelections param:");
    //     console.log(location)
    //     console.log(user)
    //     console.log(visitorInfo);
    //     console.log(dispatcher);
    // console.groupEnd();

    const [selection, subpath] = userSelection(location.hash);
    location.pathname = subpath;

    switch(selection){
        case "HOME":
            return (<UserContent.Documents {...{location, user, visitorInfo, dispatcher}}/>)
        case "ACTIVITY":
            return (<UserContent.RecentActivity />)
        case "COLLECTIONS":
            return (<UserContent.Collections />)
        case "STATUSES":
            return (<UserContent.Statuses />)
        case "FOLLOWERS":
        case "FOLLOWING":
            return (<UserContent.Users {...{selection, location, user, visitorInfo, dispatcher}}/>)
        default:
            return (
                <div className="URS-EmptySectionMessage">
                    <b>"{location.hash}"</b>
                    Unfamiliar path
                </div>
            )
    }
}

export default UserRenderSelections;