import './styling/skeletons.css'
import Repeat from './repeat'

const Skeleton = ({children, style = {}, varWidth=false, range = 100, bias=0}) => {
    if(varWidth) {
        style.width = `${Math.floor(Math.random()*range) + bias}%`
    }

    return (
    <div className='Skeleton-Div' style={style}>
        {children}    
    </div>
    )
}

const Skeletons = ({num, style}) => {
    return (
        <>
            {Array.apply(null, {length: num ?? 0}).map((v, i) => 
                <Skeleton key={i} style={style}/>
            )}
        </>
    )
}

const EmptyLine = ({range = 100, bias = 0}) => {
    const width = Math.floor(Math.random()*range) + bias;
    return <div className="Skeleton-Line" style={{'width': `${width}%`}}/>
}

const EmptyP = ({style}) => {
    const numLines = Math.floor(Math.random()*4);

    return (
        <div className='Skeleton-P' style={style}>
            <Repeat times={1}>
                <EmptyLine range={5} bias={95}/>
            </Repeat>
            <Repeat times={numLines}>
                <EmptyLine range={5} bias={95}/>
            </Repeat>
            <EmptyLine range={95} bias={5}/>
        </div>
    )
}

export default Skeletons;
export {
    Skeleton,
    EmptyLine,
    EmptyP
}