import './styling/userDisplay.css'
import { APIContext } from '../../layers';
import { useContext } from 'react';
import { pastel } from '../Misc-Components/pastel';
const UserDisplay = ({id = "0", username = "username", displayname = "displayname"}) => {
    const API = useContext(APIContext);
    const pfp = {
        backgroundColor: pastel(id),
        backgroundImage: `url(${API.getUserAvatar(username)}), url("/userpfp_default.svg")`,
    };

    return (
        <div className='UserDisplay'>
            <div className='UD-PFP' style={pfp}/>
            <div className='UD-Info'>
                <div className='UD-Displayname'>
                    <h1>{displayname}</h1>
                </div>
                {/* <div className='UD-Username'><p>Joined {getFullMonthYear(user?.timeCreated ?? null)}</p></div> */}
                <div className='UD-Username'><p>@{username}</p></div>
            </div>
        </div>
    )
}

export default UserDisplay;