import { Link } from 'react-router-dom';
import Followers from './SubUserRenders/Followers';
import Following from './SubUserRenders/Following';
import './styling/Users.css';

const Users = ({selection, location, user, visitorInfo, dispatcher}) => {

    let userSelection;
    switch(selection){
        case "FOLLOWERS": default:
            userSelection = (<Followers uid={user._id} followers={user.followers} visitorInfo={visitorInfo} dispatcher={dispatcher}/>); break;
        case "FOLLOWING":
            userSelection = (<Following uid={user._id} following={user.following} visitorInfo={visitorInfo} dispatcher={dispatcher}/>); break;
    }

    const left = selection === "FOLLOWERS";

    return (
        <>
            <div className={"User-Follows-Toggler " + (left? "Left" : "Right")}>
                <Link to={left? "#Following" : "#Followers"} draggable={false}>
                    <span className='User-Followers-Span'>Followers</span>
                    <span className='User-Following-Span'>Following</span>
                </Link>
            </div>
            {userSelection}
        </>
    );
}

export default Users;