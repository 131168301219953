import { useContext, useEffect, useRef } from "react";
import { APIContext } from "../../../../layers";
import SmallCard from "../../userSmallCard";
import './styling/Follower.css'
import { getShortDate } from "../../../Misc-Components";
import { useElementsOnScreen } from "../../../Misc-Components/useElementsOnScreen";

const Following = ({uid, following, visitorInfo, dispatcher}) => {
    const API = useContext(APIContext);
    const followingFetchRef = useRef({});
    const [containerRef, isVisible] = useElementsOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: "0.5"
    }, false);

    useEffect(() => {
        const {more, loading} = following;
        if(!isVisible || !more || loading)
            return     

        followingFetchRef.current.controller = new AbortController();
        const signal = followingFetchRef.current.controller.signal;
        dispatcher({type: "FOLLOWING_FETCH", value: {loading: true}});
        API.getFollowing.uid(uid, following.cursor, {signal})
            .then(json => {
                dispatcher({type: "FOLLOWING", value: json.following});
            })
            .catch(err => {
                if(err instanceof DOMException && err.name === "AbortError")
                    return;
            })
    }, [isVisible, uid, following, API, dispatcher]);

    useEffect(() => {
        const controller = followingFetchRef.current?.controller;

        return () => {
            if(following.loading) {
                if(controller) controller.abort();
                dispatcher({type: "FOLLOWING_ABORT", value: {loading: false}});
            }
        }
    // eslint-disable-next-line
    }, []);

    if(following.count === 0)
        return <div className="URS-EmptySectionMessage">Not following anyone</div>

    return (
        <>
        <div className='SmallUserCard-Wrapper'>
            {/* Following */}
            {following.users.map((v, i) => {
                const {_id, username, displayname} = v.following;
                return (
                    <div className='SmallCard-Container' key={username}>
                        <SmallCard 
                            userId={_id} 
                            avatarURL={API.getUserAvatar(username)} 
                            username={username} 
                            displayname={displayname}
                            
                            rightSide={<span className="FollowDate">{getShortDate(v.followedAt)}</span>}
                            />
                    </div>
                )
            })}
        </div>
        {following.more && 
            <div ref={containerRef}>&nbsp;</div>
        }
        {
            following.loading
            && 
            <div style={{justifySelf: "center"}}>
                <div className="spinner" style={{height: "2rem", width: "2rem", color: "var(--URBAN-GRAY)", justifyContent: "center"}}/>
            </div>
        }
        </>
    );
}

export default Following;