import './styling/smallCard.css'
import { Link } from 'react-router-dom';
import Userpfp from './userpfp';

const SmallCard = ({userId, username, avatarURL, rightSide, displayname}) => {

    return (
    <Link to={`/@${encodeURIComponent(username)}`} className='Small-User-Card'>
        <div className='Small-User-Card-Left'>
            <Userpfp userId={userId} userURL={avatarURL} sz={"SM"} 
                style={{height: "100%", aspectRatio: "1", width: "auto"}}
                /> 
            <div className='User-Names-Container'>
                <span className='Displayname'>{displayname}</span> <span className='Username'>@{username}</span>
            </div>
        </div>
        <div className='Small-User-Card-Right'>
            {rightSide}
        </div>
    </Link>
    )
}

export default SmallCard;