import { createContext } from "react";

const localPath =  process.env.REACT_APP_URL;
const serverPath = process.env.REACT_APP_API;
console.log('LocalPath:', localPath);
console.log('ServerPath:', serverPath);

export class HttpError extends Error {
    constructor(res) {
        super(`${res.status} for ${res.url}`);
        this.name = 'HttpError';
        this.response = res;
    }
}

async function getDoc(id) {
    let res = await fetch(`${serverPath}/D/${id}`);
    if(!res.ok){
        console.error(`An error occurred when fetching the requested Document [id: ${id}]`);
        //do something
        return;
    }

    res = await res.json();
    return res;
    }
function deleteDocument(docId, tk){

    }
function getDocImg(docID, key) {
    if(!docID || !key)
        return "";

    const path = `${serverPath}/D/${docID}/img/${key}-${docID.substr(-8)}.webp`;

    return path;
    }
function getDraftImg(d_ID, file){
    return `${serverPath}/D/d/${d_ID}/img/${file}?sz=SM`
    }
async function requestNewDraft({signal=null} = {}) {
    return await fetch(`${serverPath}/D/d`, {
        method: 'POST',
        credentials: 'include',
        signal
        });
    }
async function getDrafts({signal = null, cursor = null} = {}) {
    const url = `${serverPath}/D/d/recent` + (cursor? `?cursor=${cursor}` : "");

    return await fetch(url, {
        method: 'POST',
        credentials: 'include',
        signal
    });
    }
async function getDraft(draftID) {
    let res = await fetch(`${serverPath}/D/d/${draftID}`, {
        method: 'POST',
        credentials: 'include'
        });
    res = await res.json();
    return res;
    }
async function deleteDraft(draftID, {signal=null} = {}) {
    return fetch(`${serverPath}/D/d/${draftID}`, {
        method: 'DELETE',
        credentials: 'include',
        signal
    });
    }
async function saveDraft(draftID, formdata) {
    if(!formdata)
        return {errMsg: ["No form data provided."]};

    let res = await fetch(`${serverPath}/D/d/${draftID}`, {
        method: 'PUT',
        credentials: 'include',
        body: formdata
    });

    res = await res.json();
    return res;
    }
async function publishDraft(draftID) {
    const res = await fetch(`${serverPath}/D/d/${draftID}/pub`, {
        method: 'POST',
        credentials: 'include'
    });

    return await res.json();
    }
async function userExists(username, signal = null) {
    return await fetch(`${serverPath}/@${encodeURI(username)}?`, {signal});
    }
async function registerUser(formData, {signal}) {
    if(!formData)
        return {ok: false, status: 400};
    
    return await fetch(`${serverPath}/signup`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            signal
        });
    }
async function loginUser(formData, {signal}) {
    if(!formData)
        return {ok: false, status: 400};

    return await fetch(`${serverPath}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
        signal
        });
    }
async function logout() {
    return await fetch(`${serverPath}/logout`, {
        credentials: 'include'
    });
}
async function refresh() {
    return await fetch(`${serverPath}/refresh`, {
        method: 'POST',
        credentials: 'include'
    });
}
async function getUsersFromID(users){
    if(!users || !users.length)
        return {errMsg: ['List of users is empty']};

    if(typeof users === 'string' || users instanceof String)
        users = [users]

    if(!Array.isArray(users))
        return {errMsg: ['Users argument not of valid type: Array or string']};

    const queryString = users.reduce((p,v,i,a) => `${p}u[]=${v}${i < a.length-1? '&' : ''}`, '');
    let res = await fetch(`${serverPath}/u@?${queryString}`);
    res = await res.json();
    return res;
    }
async function getUser(username, {signal}) {
    return fetch(`${serverPath}/@${encodeURIComponent(username)}`, {
        credentials: "include",
        signal
    });
    }
function getUserImage(username, imageName) {
    if(!username)
        return "";
        
    const path = `${serverPath}/@${encodeURIComponent(username)}?get=${imageName}`;
    return path;
    }
const getUserAvatar = (username) => getUserImage(username, 'PFP');
const getUserBanner = (username) => getUserImage(username, 'BANNER');
async function changeAvatar(formdata) {
    let res = await fetch(`${serverPath}/change-avatar`, {
        method: 'POST',
        credentials: 'include',
        body: formdata
    });
    res = await res.json();
    return res;
    }
async function changeUserBio(newBio, tk){
    let res = await fetch(`${serverPath}/change-bio`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({bio: newBio})
    });
    res = await res.json();
    return res;
    }
async function changeSettings(setting, body, tk){
    let res = await fetch(`${serverPath}/settings/${setting}`, {
        method: 'POST',
        credentials: 'include',
        body: body
    });
    res = await res.json();
    return res;
}
async function followUser(targetUser, action) {
    // target: user being followed
    // action: [FOLLOW, UNFOLLOW]
    //  -> FOLLOW action: Tells the server to try having the sender follow the target. If the sender is already following them, send a message back.
    //  -> UNFOLLOW action: Tells the server to try having the sender unfollow the target. If the sender does not follow the target, send a message back.
    const ActionMethod = {'FOLLOW': 'POST', 'UNFOLLOW': 'DELETE'};
    const method = ActionMethod[action.toUpperCase()];
    if(!method)
        return {ok: false, status: 400}; 
    
    return await fetch(`${serverPath}/follow@${targetUser}`, {
        method,
        credentials: 'include'
    });
}
// function getUserActivity() {}
// function getUserStatuses() {}
async function _getDocuments(user, cursor, {signal}) {
    if(!user)// || !(typeof page !== 'number'))
        return {status: 400};

    const url = `${serverPath}/${user}?get=DOCS` + (cursor? `&cursor=${cursor}` : "");
    let res = await fetch(url, {signal});
    if(!res.ok){
        return res.status;
    }
    res = await res.json();
    return res;
    }
async function _getFollow(action, username, cursor, {signal}){
    if(!username)//|| (typeof cursor !== "string"))
        return {status: 400};

    const url = `${serverPath}/${username}?get=${action}` + (cursor? `&cursor=${cursor}` : "")
    let res = await fetch(url, {signal});
    if(!res.ok) {
        return res.status;
    }
    
    res = await res.json();
    return res;
    }

const getDocuments = {
    username: async (username, cursor, opts={}) => _getDocuments(`@${encodeURIComponent(username)}`, cursor, opts),
    uid: async (uid, cursor, opts={}) => _getDocuments(`u@${encodeURIComponent(uid)}`, cursor, opts)
    }
const getFollowers = {
    username: async (username, cursor, opts={}) => _getFollow("FOLLOWERS", `@${encodeURIComponent(username)}`, cursor, opts),
    uid: async (uid, cursor, opts={}) => _getFollow("FOLLOWERS", `u@${encodeURIComponent(uid)}`, cursor, opts)
    }
const getFollowings = {
    username: async (username, cursor, opts={}) => _getFollow("FOLLOWINGS", `@${encodeURIComponent(username)}`, cursor, opts),
    uid: async (uid, cursor, opts={}) => _getFollow("FOLLOWINGS", `u@${encodeURIComponent(uid)}`, cursor, opts)
    }

async function getHomeFeed(page) {
    const res = await fetch(`${serverPath}/h/${page}`);
    return await res.json();
    }

// ===================================== //

const APIContext = createContext();

const APILayer = ({children}) => {
    const API = {
        getDoc,
        deleteDocument,
        getDocImg,
        requestNewDraft,
        getDrafts,
        getDraft,
        getDraftImg,
        deleteDraft,
        saveDraft,
        publishDraft,
        userExists,
        registerUser,
        loginUser,
        logout,
        refresh,
        getUsersFromID,
        getUser,
        getUserAvatar,
        getUserBanner,
        changeAvatar,
        changeUserBio,
        changeSettings,
        followUser,
        getDocuments,
        getFollowers,
        getFollowings,
        getHomeFeed,
    };

    return(
        <APIContext.Provider value={API}>
            {children}
        </APIContext.Provider>
    )
}

export default APILayer;
export {
    APIContext
}