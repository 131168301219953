import { useContext, useEffect, useRef} from "react";
import { APIContext } from "../../../layers";
import DocCard from "../../Document-Components/v2/docCard";
import { useElementsOnScreen } from "../../Misc-Components/useElementsOnScreen";
import './styling/Documents.css'
import './styling/SubRender.css'

const Documents = ({location, user, visitorInfo, dispatcher}) => {
    const API = useContext(APIContext);
    const docFetchRef = useRef({});
    const [containerRef, isVisible] = useElementsOnScreen({
        root: null, 
        rootMargin: "0px", 
        threshold: "0.5"
    }, false);

    useEffect(() => {
        const {more, loading} = user.documents;
        if(!isVisible || !more || loading)
            return;
        
        docFetchRef.current.controller = new AbortController();
        const signal = docFetchRef.current.controller.signal;
        dispatcher({type: "DOCUMENTS_FETCH", value: {loading: true}});
        API.getDocuments.uid(user._id, user.documents.cursor, {signal})
            .then(json => {
                dispatcher({type: "DOCUMENTS", value: json.docs});
            })
            .catch(err => {
                if(err instanceof DOMException && err.name === "AbortError")
                    return;
            })
    }, [isVisible, user, API, dispatcher]);

    useEffect(() => {
        const controller = docFetchRef.current?.controller
        
        return () => {
            if(user.documents.loading) {
                if(controller) controller.abort();
                dispatcher({type: "DOCUMENTS_ABORT", value: {loading: false}})
            }
        }
    // eslint-disable-next-line
    }, [])

    if(!user.fetched)
        return <></>;

    if(user.docsPublished === 0) 
        return <div className="URS-EmptySectionMessage">No documents published</div>

    return (
        <>
        <div className='URS-Documents-Container'>
            {user.documents.docs.map((doc, index) => <DocCard doc={doc} key={index} />)}
        </div>
        {user.documents.more && 
            <div ref={containerRef}>&nbsp;</div>
        }
        {
            user.documents.loading
            && 
            <div style={{justifySelf: "center"}}>
                <div className="spinner" style={{height: "2rem", width: "2rem", color: "var(--URBAN-GRAY)", justifyContent: "center"}}/>
            </div>
        }
        </>
    );
}

export default Documents;