import './styling/limit_input.css'

const LimitInput = ({
    lim = 0,
    placeholder = "", 
    value = "", 
    onChange,
    onFocus, 
    name,
    id, 
    onBlur, 
    className="", 
    handleOverLim = false, 
    hasError = false,
    disableSpellCheck = false,
    disabled=false,
    required=false,
    autoComplete = 'off',
}) => {

    const interceptOnChange = (e) => {
        if(!onChange) return;

        if(e.target.value.length > lim && !handleOverLim)
            return;

        onChange(e);
    }

    const interceptOnFocus = (e) => {
        if(!onFocus) return;
        onFocus(e);
    }

    const interceptOnBlur = (e) => {
        if(!onBlur) return;

        let val = e.target.value;
        if(val.length > lim)
            val = val.slice(0, lim);
        onBlur(val);
    }

    const Name = name? name : placeholder;
    const invalidClass = hasError? 'limit-input-Invalid' : '';
    return(
        <div className={`limit-input ${className} ${invalidClass}`}>
            <input 
                name={Name}
                id={id}
                value={value ?? ""}
                onChange={interceptOnChange} 
                onFocus={interceptOnFocus}
                placeholder={placeholder} 
                autoComplete={autoComplete}
                onBlur={interceptOnBlur}
                spellCheck={!disableSpellCheck}
                disabled={disabled}
                required={required}
                /> 
            <span>{lim - (value?.length ?? 0)}</span>
        </div>
    )
}

export default LimitInput;