import { Userpfp } from "../../../User-Components";
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { APIContext } from "../../../../layers/api";
import './styling/author.css'
import { getShortDate } from "../../../Misc-Components";

// sz = [SM, M, L]
const Author = ({user, date=null, sz='SM', haveLink=true, softPFP=true}) => {
    const API = useContext(APIContext);
    const {_id, username, displayname} = user;

    if(haveLink) {
        return(
            <div className='Authorship'>
            <Link to={`/@${username}`} className='Authorship-Link'>
                <Userpfp 
                    userId={_id}
                    userURL={API.getUserAvatar(username)}
                    sz={sz}
                    softPFP={softPFP}
                    /> 
                <p>
                    by {displayname}
                    <span style={{color: "rgba(0,0,0,.65", fontWeight: "400"}}> @{username}
                    {
                        date && !isNaN(new Date(date).valueOf()) && 
                        <> <b style={{"padding":"0pt 1pt"}}>&middot;</b> {getShortDate(date)}</>
                    }
                    </span>
                </p>
            </Link>
            </div>
        )
    }
    else {
        return (
        <div className='Authorship'>
            <Userpfp 
                userId={_id}
                userURL={API.getUserAvatar(username)}
                sz={sz}
                softPFP={softPFP}
                /> 
            <p>
                by {displayname}
                <span style={{color: "rgba(0,0,0,.65", fontWeight: "400"}}> @{username}</span>
            </p>
        </div>
        )
    }
}

export default Author;