import {useReducer, useEffect, useContext} from 'react'
import { Helmet } from 'react-helmet';
import { APIContext, AuthContext, NotifContext } from '../layers';
import { Link, useNavigate } from 'react-router-dom';
import {
    UserBanner,
    UserDisplay,
    UserStats,
    UserRenderSelections as RenderSelections,
    UserAbout as About,
    AsideFollowers,
    AsideFollowing,

    UserSkeleton,
    } from '../components/User-Components/';
import './styling/User.css'
import { SmallAbout } from '../components/User-Components/UserAbout';
import PageNotFound from './404';
import { HttpError } from '../layers/api';

const PAGE_SIZE = 10;

function print(item) {
    if(!item)
        return `${item}`

    switch(typeof item) {
        case "string":
        case "number":
            return item;
        case "boolean":
            return (item? "true": "false");
        case "object":
            if(Array.isArray(item))
                return (
                    <ul>
                        [
                        {
                            item.map((v, i) => <li key={i}>{print(v)}</li>)
                        }
                        ]
                    </ul>
                )
            else
                return (
                    <ul>{
                        Object.keys(item).map(v => <li key={v}>{v}: {print(item[v])}</li>)    
                    }</ul>
                )
        default:
            return `${item}`
    }
}

/*
userInfo:
    username: STRING
    avatar: STRING
    bio: STRING
    docsPublished: NUM
    followerCount: NUM
    followingCount: NUM
    followers: ARRAY (PAGE 0)
    followers: ARRAY (PAGE 0)
    groupsCreated: NUM
    statusesCreated: NUM
    timeCreated: DATE

visitorInfo:
    followsUser: BOOL
    hasModPriv: BOOL
    isUser: BOOL
*/

function UserReduce(state, action) {
    const NewState = {...state};
    switch(action.type) {
        case "LEAVING":
            NewState.user.fetched = false;
            for(const key in NewState.loading) {
                NewState.loading[key]?.abort();
            }
            NewState.loading = {};
            break;
        case "USER": {
            console.log(action);
            const {user, visitorInfo} = action.value;
                NewState.user = {
                    fetched: true,
                    failed: false,
                    username: user.username,
                    _id: user._id ?? 0,
                    displayname: user.displayname ?? "",
                    docsPublished: user.docsPublished ?? 0,
                    timeCreated: user.timeCreated ?? "",
                    bio: user.bio ?? "",
                    documents: {
                        count: user.docsPublished ?? 0,
                        more: user.docsPublished > 0,
                        cursor: null,
                        docs: [],
                        loading: false,
                        failed: false,
                        },
                    followers: {
                        count: user.followerCount ?? 0,
                        more: true,
                        cursor: null,
                        users: [],
                        loading: true,
                        failed: false,
                        },
                    following: {
                        count: user.followingCount ?? 0,
                        more: true,
                        cursor: null,
                        users: [],
                        loading: true,
                        failed: false,
                        }
                }

                NewState.visitorInfo = {...NewState.visitorInfo, ...visitorInfo};
            }
            break;
        case "NOT_FOUND":
            NewState.user.fetched = true;
            NewState.user[404] = true;
            break;
        case "FOLLOWERS": {
            NewState.user.followers.users.push(...action.value);
            const more = action.value.users >= PAGE_SIZE;
            NewState.user.followers.more = more;
            NewState.user.followers.cursor = more && action.value.at(-1).followedAt;
            NewState.user.followers.loading = false;
            NewState.user.followers.failed = false;
            }
            break; 
        case "FOLLOWING":{
            NewState.user.following.users.push(...action.value);
            const more = action.value.length >= PAGE_SIZE; 
            NewState.user.following.more = more; 
            NewState.user.following.cursor = more && action.value.at(-1).followedAt;
            NewState.user.following.loading = false;
            NewState.user.following.failed = false;
            }
            break; 
        case "FOLLOW_API":
            NewState.loading.follow = action.value;
            break;
        case "FOLLOW_API_END":
            delete NewState.loading.follow;
            break;
        case "FOLLOWS_USER": {
            const {_id, displayname, username} = action.value;
            NewState.user.followers.users.unshift({
                follower: {
                    _id,
                    displayname,
                    username
                },
                followedAt: Date.now()
            })
            NewState.user.followers.count++;

            NewState.visitorInfo.followsUser = true;
            delete NewState.loading.follow;
        }
        break;
        case "UNFOLLOWS_USER": {
            const {_id} = action.value;
            NewState.user.followers.users = NewState.user.followers.users
            .filter(({follower}) => follower._id !== _id);
            NewState.user.followers.count--;
            
            NewState.visitorInfo.followsUser = false;
            delete NewState.loading.follow;
            }
            break;
        case "DOCUMENTS": {
            NewState.user.documents.docs.push(...action.value);
            const more = action.value.length >= PAGE_SIZE;
            NewState.user.documents.more = more;
            NewState.user.documents.cursor = more && action.value.at(-1).timeCreated;
            NewState.user.documents.loading = false;
            NewState.user.documents.failed = false;
            }
            break;
        case "DOCUMENTS_FETCH": 
            NewState.user.documents.loading = action.value.loading; 
            break;
        case "DOCUMENTS_ABORT": 
            NewState.user.documents.loading = action.value.loading; 
            break;
        case "ACTIVITY":
            break;
        case "POSTS":
            break;
        case "COLLECTIONS":
            break;
        default: break;
    }

    return NewState;
}

const User = ({username, location}) => {
    const API = useContext(APIContext);
    const AUTH = useContext(AuthContext);
    const Notif = useContext(NotifContext);
    const [userInfo, dispatchUserInfo] = useReducer(UserReduce, {
        user: {
            fetched: false,
            failed: false,
            username: username.toLowerCase(), // string
            404: false,
            // _id: ObjectID
            // displayname: string 
            // dateJoined: Date
            // documents: {
            //     count: Int
            //     docs: [{docId, title, subtitle, timePublished, ...}]
            //     more: bool
            //     cursor: id
            //     loading: bool
            //     failed: bool
            //     },
            // followers: {
            //     count: Int
            //     users: [{userId, displayname, username, timeFollowed}]
            //     more: bool
            //     cursor: date
            //     fetched: bool
            //     loading: bool
            //     failed: bool
            //     }, 
            // following: {
            //     count: Int
            //     users: [{userId, displayname, username, timeFollowed}]
            //     more: bool
            //     cursor: date
            //     fetched: bool
            //     loading: bool
            //     failed: bool
            //     }
        },
        visitorInfo: {
            loggedIn: !!(AUTH.User),
            hasModPriv: !!(AUTH.User?.isAdmin), 
            isUser: false, // delegated to backend check cause does not update when navigating between user pages 
            followsUser: false, // requires check
            blockedUser: false, // requires check // Visitor has blocked the user
            blockedByUser: false // requires check // User has blocked the visitor
            },
        loading: {}
    })
    const navigate = useNavigate();

    useEffect( () => {
        const aborts = {
            user: new AbortController()
        }

        API.getUser(username, {signal: aborts.user.signal})
        .then( json => {
                dispatchUserInfo({type: "USER", value: json})
                
                API.getFollowers.uid(json.user._id, null)
                   .then(json => {
                        dispatchUserInfo({type: "FOLLOWERS", value: json.followers})
                    })
                    
                API.getFollowings.uid(json.user._id, null)
                .then(json => {
                    dispatchUserInfo({type: "FOLLOWING", value: json.following})
                })
        })
        .catch(err => {
            if(err instanceof DOMException && err.name === "AbortError")
                return;
            
            console.error(err.message)
            if(err instanceof HttpError) {
                if(err.response.status === 404)
                    dispatchUserInfo({type: "NOT_FOUND"});
                return;
            }

            // dispatchUserInfo({type: "FAILED"})
        });

        return () => {
            aborts.user.abort()
            dispatchUserInfo({type: "LEAVING"});
        }
    // ignore API dependency
    // eslint-disable-next-line
    }, [username]);

    async function onClick_FollowButton(action) {

        userInfo.loading.follow?.abort();
        const abortSignal = new AbortController();
        dispatchUserInfo({type: "FOLLOW_API", value: abortSignal});
        const signal = abortSignal.signal;

        API.followUser(username, action, {signal})
        .then(res => {
            if(action === "FOLLOW"){
                dispatchUserInfo({type: "FOLLOWS_USER", value: AUTH.User});
                Notif.createNotif([], "", `@${username} followed`)
            }
            else {
                dispatchUserInfo({type: "UNFOLLOWS_USER", value: AUTH.User});
                Notif.createNotif([], "", `@${username} unfollowed`)
            }
        })
        .catch(async err => {
            dispatchUserInfo({type: "FOLLOW_API_END"});
            if(err instanceof DOMException && err.name === "AbortError")
                return;
            Notif.createNotif(["error"], "", `Could not ${action.toLowerCase()} user.`)
        });
    }

    const FollowButton = 
            userInfo.visitorInfo.isUser ?? true 
            ? <></>
            : ( userInfo.visitorInfo.followsUser
                ? <button onClick={() => onClick_FollowButton("UNFOLLOW")} disabled={userInfo.loading.follow} className='Fllw-Bttn  Unfllw-Button'>- unfollow</button>
                : <button onClick={() => onClick_FollowButton("FOLLOW")} disabled={userInfo.loading.follow} className='Fllw-Bttn  Follow-Button'>+ follow</button>
            )

    function navigateTo(path) {
        return (e) => {
            e.preventDefault();
            navigate(path);
        }
    }

    if(!userInfo.user.fetched)
        return (<UserSkeleton />)

    if(userInfo.user[404])
        return (<UserNotFound user={userInfo.user}/>)

    return (
        <>
        <div style={{
            position: "absolute", 
            padding: "1em",
            color: "#FFFE",
            backgroundColor: "#0008", 
            width: "100%", 
            height: "100%",
            overflowY: "scroll",
            zIndex: "999",
            display: "none"
            }}>
            <h2>User:</h2>
            <ul>
                {
                    print(userInfo.user)
                }
            </ul>
            <h2>Visitor Info:</h2>
            <ul>
                {
                    print(userInfo.visitorInfo)
                }
            </ul>
        </div>
        <div id='User'>
            <Helmet titleTemplate='@%s - UrbanCoffee.io'>
                <title>{userInfo.user.username ?? "..."}</title>
                <meta name="description" content={userInfo.user.bio ?? ""}/>
            </Helmet>
            <div className='User-Container'>
                <main>
                    <UserBanner src={API.getUserBanner(userInfo.user.username)}/>
                    <UserDisplay id={userInfo.user._id} 
                                 username={userInfo.user.username}
                                 displayname={userInfo.user.displayname}/>
                    <div className='User-Content'>
                        <div className='SmallWidth'>
                            <div className="UC-Bio-Header">
                                <h3>About</h3>
                                {FollowButton}
                            </div>
                            <div className='UC-Bio-Body'>
                                <SmallAbout bio={userInfo.user.bio ?? ""} light/> 
                            </div>
                        </div>
                        <UserStats user={userInfo.user}/>
                        <ul className='User-Selections'>
                            <li className={location.hash === ""? 'selected' : ''}>
                                <Link to={location.userpath ?? "/"} onClick={navigateTo(location.userpath ?? "/")}>Documents</Link>
                            </li>
                            <li className={/^#Activity(\/.*)?$/i.test(location.hash)? 'selected' : ''}>
                                <Link to="#Activity" onClick={navigateTo("#Activity")}>Activity</Link>
                            </li>
                            <li className={/^#Collections(\/.*)?$/i.test(location.hash)? 'selected' : ''}>
                                <Link to="#Collections" onClick={navigateTo("#Collections")}>Collections</Link>
                            </li>
                            <li className={/#Statuses(\/.*)?$/.test(location.hash)? 'selected' : ''}>
                                <Link to="#Statuses" onClick={navigateTo("#Statuses")}>Statuses</Link>
                            </li>
                            <li className={/^#Follow(ers|ing)(\/.*)?$/i.test(location.hash)? 'selected' : ''}>
                                <Link to="#Followers" onClick={navigateTo("#Followers")}>Follows</Link>
                            </li>
                        </ul>
                        <div className="User-Content-Container">
                            <RenderSelections 
                                location={location}
                                user={userInfo.user}
                                visitorInfo={userInfo.visitorInfo}
                                dispatcher={dispatchUserInfo}
                                />
                        </div>
                    </div>
                    <div style={{height: "min(50cqh, 100px)", width: "10px"}}/>
                </main>
                <aside>
                    <About bio={userInfo.user.bio ?? ""}/>
                    <AsideFollowing username={userInfo.user.username}
                                    following={userInfo.user.following} 
                                    visitorInfo={userInfo.visitorInfo} 
                                    />
                    <AsideFollowers username={userInfo.user.username}
                                    followers={userInfo.user.followers} 
                                    visitorInfo={userInfo.visitorInfo} 
                                    FollowButton={FollowButton}
                                    />
                    {/*<AccountComments /> */}
                 </aside>
            </div> 
        </div>
        </>
    )
}

function UserNotFound({user}) {
    return (
    <div id="p404">
        <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
        </h1>
        <p>User <b>@{user.username}</b> not found</p>
    </div>
    )
}

function UserRouter({location, username}){
    location.pathname = location.pathname.replace(/^\/@[\w._-]*/, "")
    if(!/^\/?$/.test(location.pathname))
        return <PageNotFound path={location.userpath + location.pathname}/>
    return <User username={username} location={location}/>
}

export {User};
export default UserRouter;