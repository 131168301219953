import ImgButton from "./imgbutton";
import { Menu, Anchor, CMenu } from "./menu";
import AutoTextArea from "./auto_textarea";
import LimitInput from "./limit_input";
import Important from './important';
import LoadingCircle from './LoadingCircle';
import NMIS from "./noModalImageSelector";
import CropModal from './CropModal';
import Skeletons, {Skeleton, EmptyLine, EmptyP} from "./skeletons.js";
import Repeat from "./repeat";
const Denoms = ['', 'K', 'M', 'B', 'T'];
const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November', 'December']

const TimeUnits = [
    ["milliseconds", 1],
    ["seconds", 1000],//ms,
    ["minutes", 60],//s
    ["hours", 60],//m
    ["days", 24],//hr
    //no months
    ["years", 365],//d
    ["decades", 10]//y
]

const TimeSinceDisplays = [
    ["decades", "decade", "dec"],
    ["years", "year", 'yr'],
    ["days", "day", 'd'],
    ["hours", "hour", 'hr'],
    ["minutes", "minute", 'm'],
    ["seconds", "seconds", 's'],
    ["milliseconds", "millisecond", 'ms']
]


function getFullDate(date) { // Month Day, Year
    if(!date)
        return 'January 1, 2000';

    let d = new Date(date);
    // console.log('d.getDate():', d.getDate());

    const retString = `${Months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
    return retString;
}

function getShortDate(date) { // Month{3} Day, Year
    if(!date)
        return 'Jan 1, 2000'

    let d = new Date(date);
    return `${Months[d.getMonth()].substring(0, 3)} ${d.getDate()}, ${d.getFullYear()}`
}

function getFullMonthYear(date) { // Month Year
    if(!date)
        return 'January 2000';

    let d = new Date(date);
    // console.log('date (d):', d);
    // console.log('date.getDate:', d.getDate());

    const retString = `${Months[d.getMonth()]} ${d.getFullYear()}`;
    return retString;
}

function getShortMonthYear(date) {
    if(!date)
        return 'Jan 2000';

    let d = new Date(date);
    return `${Months[d.getMonth()].substring(0,3)} ${d.getFullYear()}`
}

function timeElapsed(Past, Future) {
    const elapses = {
        decades: 0,
        years: 0,
        // no months
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
        inFuture: false
    }

    if(!Past || !Future)
        return elapses;

    let remainder = new Date(Future) - new Date(Past);
    if(remainder < 0) {
        elapses.inFuture = true;
        remainder *= -1;
    }

    for(let i = 0; i + 1 < TimeUnits.length; i = i + 1) {
        const t = Math.floor(remainder / TimeUnits[i+1][1]);
        elapses[TimeUnits[i][0]] = remainder - t * TimeUnits[i+1][1];

        remainder = t;
        if(remainder === 0) return elapses;
    }

    elapses.decades = remainder;
    return elapses;
}

function timeSince(Start, End, {precision = 1, fullUnit = false, avoidNow = false, allUnits = false} = {}) {
    const elapses = timeElapsed(Start, End);
    
    let output = "";
    for(let i = 0; i < TimeSinceDisplays.length && precision > 0; i++) {
        const unit = TimeSinceDisplays[i];
        if(!avoidNow && output.length === 0 && unit[0] === "seconds")
            break;

        // TODO
        const count = elapses[unit[0]]
        if(count === 0 && !allUnits)
            continue;

        output += ` ${count}` + (
            fullUnit
                ? ` ${(count === 1? unit[1] : unit[0])}`
                : unit[2]
        )
        precision--;
    }
    if(output.length)
        return output.trimStart() + (elapses.inFuture? " from now" : " ago");
    else
        return "Now"
}

function simplifyNum(num){
    let Num = parseInt(num);

    if(isNaN(Num))
        return {value: '0', denom: ''};


    // find out which power of 1000 Num is smaller than.
    // Only go up to a trillion. Hence the 'i < 5'
    let i = 1;
    for(; i <= 5; i++){
        if(Num < Math.pow(1000, i)){
            i--;
            break;
        }
    }
    
    Num = Math.floor((Num / Math.pow(1000, i))*10)/10;

    return {value: Num, denom: Denoms[i]};
}

async function getImageDim(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            const image = new Image();
            image.src = e.target.result;
            await image.decode();

            resolve({width: image.naturalWidth, height: image.naturalHeight});

        }
        reader.readAsDataURL(file);
    })
}

function debounce(cb, delay = 1000) {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => cb(...args), delay);
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
async function digest(algo, message) {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await window.crypto.subtle.digest(algo, msgUint8);
    return Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2,"0"))
        .join("");
}

const digest256 = async msg => digest("SHA-256", msg); 
const digest512 = async msg => digest("SHA-512", msg); 

export {
    ImgButton,
    AutoTextArea,
    Menu, Anchor, CMenu,
    LimitInput,
    Important,

    getFullDate,
    getFullMonthYear,
    getShortDate,
    getShortMonthYear,
    timeElapsed,
    timeSince,
    simplifyNum,
    
    getImageDim,

    LoadingCircle,

    Skeletons,
    Skeleton,
    EmptyLine,
    EmptyP,
    
    Repeat,

    NMIS,
    CropModal,

    debounce,
    digest256,
    digest512,
}