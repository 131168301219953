import { useContext } from "react";
import { APIContext } from "../../layers";
import { Link } from "react-router-dom";
import {ReactComponent as PlusCircleSVG} from '../../icons/svg/plus-circle.svg';
import { simplifyNum } from "../Misc-Components";
import Userpfp from "./userpfp";
import SmallCard from './userSmallCard';
import './styling/Follows.css'

const Following = ({username, following, visitorInfo}) => {
    const API = useContext(APIContext);

    let moreFollows = 0;
    if(following) {
        const {value, denom} = simplifyNum(following.count - 4);
        moreFollows = `${value}${denom}`;
    }

    return(
        <div className='FllwTemplate'>
            <div className="FllwTemplate-Header">
                <h2>Following</h2>
            </div>
            <div className="FllwTemplate-Body">
            {!following && <div className='FllwTemplate-User FllwTemplate-User-Placeholder' /> }
            {following?.users.slice(0,4).map((v,i) => {
                const {_id, username, displayname} = v.following;
                    return (<div key={username} className='FllwTemplate-User'>
                                <SmallCard userId={_id} avatarURL={API.getUserAvatar(username)} username={username} displayname={displayname}/>
                            </div>)
                })}
            {following && !following.users.length &&  
                <div className="FllwTemplate-Body-Empty">
                    {`${visitorInfo.isUser? "You are" : `@${username} is`} not following anyone.`}
                </div>
            }
            </div>
            {
                following && following.count > 4
                &&
                <Link className='FllwTemplate-More' to="#Following">
                    <div className="Stack">
                        <div className="Plus">
                            <PlusCircleSVG style={{width: "100%", height: "100%"}}/>
                        </div>
                        {following?.users.slice(4, 8).map((v,i) => {
                            const {_id, username} = v.following;
                            return <Userpfp key={_id} userId={_id} userURL={API.getUserAvatar(username)} style={{height: '1.5rem', width: '1.5rem', zIndex: `-${i}`}}/>
                        })}
                    </div>
                    <div className="More">{moreFollows} more</div>
                </Link>
            }
        </div>
    )
}

export default Following;