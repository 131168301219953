import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './misc-stylings/loaders.css'
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import './components/Misc-Components/styling/misc-styling'

import Layout from './layers/Layout.js'

import Home from './routes/Home.js'
import Article from './routes/Article.js'
import Create from './routes/Create-ROUTE/Create_v2.js'
//  import CreatePLACEHOLDER from './routes/Create-ROUTE/Create_v2-PLACEHOLDER.js'
import DraftCollection from './routes/DraftCollection';
import UserRouter from './routes/User.js'
import Login from './routes/Login.js'
import Signup from './routes/Signup.js'
import Settings from './routes/Settings.js'

import Admin from './routes/Admin-ROUTE/Admin.js'
import PageNotFound from './routes/404.js';

function Subrouter() {
  const loc = useLocation();
  // console.log(loc);
  const userRoute = loc.pathname.match(/@(?<username>[\w._-]*)/);
  // console.log(userRoute)
  if(userRoute) {
    loc.userpath = `/${userRoute[0]}`;
    return <UserRouter location={loc} username={userRoute.groups["username"]}/>
  }
  return <PageNotFound path={loc.pathname}/>
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index                      element={<Home/>} />
        <Route path='/article/:docID'     element={<Article/>} />
        <Route path='/create'             element={<DraftCollection/>} />
        <Route path='/create/:draftID'    element={<Create/>} />
        <Route path='/login'              element={<Login/>} />
        <Route path='/signup'             element={<Signup/>} />
        <Route path='/settings/*'         element={<Settings/>} />

        <Route path='/A'    element={<Admin/>} /> 
        {/*
        <Route path='/search'   element={<Search/>} />
        <Route path='/messages' element={<Messages/>} />
        */}
        <Route path='/*'    element={<Subrouter/>} />

      </Route>
    </Routes>
  </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
