import './styling/navbar.css'
import { useContext } from 'react';
import { APIContext, AuthContext /*, NotifContext*/} from '../../layers';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import NavLink, {HamMenuOpt, UserNavLink, UserHamMenuOpt} from './navbtn.js'

import {ReactComponent as UrbCofLogo} from '../../icons/svg/urbcof_logo.svg'
import {ReactComponent as UserIcon} from '../../icons/svg/user.svg'
import {ReactComponent as CreateIcon} from '../../icons/svg/file-pen.svg'
import {ReactComponent as AdminIcon} from '../../icons/svg/toolbox.svg'
import {ReactComponent as GearIcon} from '../../icons/svg/gear.svg'

const NavBar = () => {
    const API = useContext(APIContext);
    const Auth = useContext(AuthContext);
    const [HamMenuOut, setHamMenuOut] = useState(false);
    const User = Auth.User;
    const navigate = useNavigate();

    let navOptions = [(<></>),(<></>)],
        secondaryOptions = [(<></>),(<></>)];

    if(!User){
        navOptions = [(
            <NavLink to='/login'
                icon={<UserIcon/>}
                title="Log in"
                padding='30'            
                />
        ), (
            <div className='CNav-HamMenu-Opt' onClick={() => {setHamMenuOut(false); navigate('/login')}}>
                <UserIcon/>
                <span>Log In</span>
            </div>
        )];
        secondaryOptions[0] = (
            <div className='alpha-badge alpha-div'><span>alpha</span></div>
        );
    } else {
        const UserAvatar = API.getUserAvatar(User.username);

        navOptions = [(
            <>
            <UserNavLink User={User} UserAvatar={UserAvatar}/>
            <NavLink to='/create'
                icon={<CreateIcon/>}
                title="Create"
                padding='25'    
                />
            </>
        ), (
            <>
                <UserHamMenuOpt 
                    User={User}
                    UserAvatar={UserAvatar}
                    onClick={() => {setHamMenuOut(false); navigate(`/@${encodeURIComponent(User.username)}`)}}
                    />
                <HamMenuOpt onClick={() => {setHamMenuOut(false); navigate('/create')}}>
                    <CreateIcon />
                    <span>Create</span>
                </HamMenuOpt>
            </>
        )]
        secondaryOptions = [(
            <>
            <div className='alpha-badge alpha-div'><span>alpha</span></div>
            {
                User.isAdmin ?
                <NavLink to='/A'
                    icon={<AdminIcon/>}
                    title='Admin'
                    padding='30'
                    adminBtn
                    />
                : <></>
            }
            <NavLink to='/settings/'
                icon={<GearIcon/>}
                title="Settings"
                padding='25'
                />
            </>
        ), (
            <>
            {
                User.isAdmin ?
                <div className='CNav-HamMenu-Opt' onClick={() => {setHamMenuOut(false); navigate('/A')}}>
                    <AdminIcon />
                    <span>Admin Panel</span>
                </div>
                : <></>
            }
            <div className='CNav-HamMenu-Opt' onClick={() => {setHamMenuOut(false); navigate('/settings/')}}>
                <GearIcon />
                <span>Settings</span>
            </div>
            </>
        )]
    }


    return (
        <nav>

            <div className='Reg-Nav'>
                <div className='Nav-Primary'>
                    <NavLink to="/"
                        icon={<UrbCofLogo />}                    
                        />
                    {navOptions[0]}
                </div>

                <div className='Nav-Secondary'>
                    {secondaryOptions[0]}
                </div>
            </div>

            <div className='Comp-Nav'>
                <a className='CNav-Name' href='/'>
                    <span>
                    UrbanCoffee.io <span className='alpha-badge'><span>alpha</span></span>
                    </span>
                </a>
                <button className='CNav-Ham' onClick={() => setHamMenuOut(prev => !prev)}>
                    {HamMenuOut 
                        ? <span className='reduceTransform'>&#9587;</span> //&#128473;</span> 
                        : <span>&#9776;</span>}
                </button>
                <div className={`CNav-Blocker ${HamMenuOut? 'CNav-HamBlocker-Out' : ''}`} onClick={() => setHamMenuOut(false)}/>
                <div className={`CNav-HamMenu ${HamMenuOut? 'CNav-HamMenu-Out' : ''}`}>
                    {navOptions[1]}
                    {secondaryOptions[1]}
                </div>
            </div>

        </nav>
    )
}

export default NavBar;